import React from 'react';

//components
import Layout from '../../../shared/layout'
import AdminUsersComp from '../../../components/admin-dashboard/users-comp'

const UsersPage = () => {
  return (
    <>
        <Layout>
            <AdminUsersComp />
        </Layout>
    </>
  )
}

export default UsersPage