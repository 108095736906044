import React from 'react';
import { Link } from 'react-router-dom';

// logo
import logo from '../../assets/images/logo-yellow.png';

const TermsAndConditionComp = () => {
  return (
    <>
        <div className='w-full'>
            <div className="relative top-0 bg-[#FF971D] text-white">
                <h1 className='ml-16 mr-auto w-10/12 py-16 text-2xl lg:text-4xl font-bold'>Terms and Conditions</h1>
                <div className="absolute right-4 -bottom-16 rounded-full bg-white px-6 py-10 w-max h-max shadow-lg">
                    <Link to="/">
                        <img src={logo} width="80px" height="80px" alt="logo" />
                    </Link>
                    {/* <h2>Logo</h2> */}
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Eligibility:</h3>
                    <p className='text-lg font-normal my-8'>
                        To be eligible to use or access the SmartHills Platform and the SmartHills Services, 
                        the following conditions must be met by you –
                    </p>

                    <ol>
                        <li className='my-4'>1. must create a Patricia user account on the SmartHills Platform;</li>
                        <li className='my-4'>2. as an individual, be at least 18 or be of legal age to form a binding contract under applicable laws;</li>
                        <li className='my-4'>3. as an individual, legal person, or other organization, you have full power, authority and capacity to accept these Terms of Use;</li>
                        <li className='my-4'>4. as an employee or agent of a legal entity, you must have the full power, authority and capacity to accept these Terms of Use on their behalf and bind such legal entity to these Terms of Use;</li>
                        <li className='my-4'>5. you have not been previously restricted from using or accessing the SmartHills Platform and SmartHills Services</li>
                        <li className='my-4'>6. your use of SmartHils Services will not violate any applicable laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.</li>
                    </ol>
                </div>
            </div>

            {/* principal provisions */}
            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Principal provisions:</h3>

                    <ol className='my-8'>
                        <li className='my-4'>1. These Terms and Conditions of Service are the primary regulation of the relationship between SmartHills Investment head office located in …, and the Client within the framework of these T&Cs and provisions of the International Law unless otherwise specified by these T&Cs.</li>
                        <li className='my-4'>2. Any private individuals of or above 18 years old who have completed their registration on the official website, www.smarthills.org (the Website hereinafter), becomes a client and a party to the cooperation with the Company regulated by these T&Cs.</li>
                        <li className='my-4'>3. The Company may not be held responsible for any consequences related to the investment activity of the Client. The Company may not act as an insurance agent for the provided services or taken actions.</li>
                        <li className='my-4'>4. By creating a personal account on the Website, the Client acknowledges that they have read and understood these T&Cs and accept them fully.</li>
                        <li className='my-4'>5. The Client acknowledges that they make investments voluntarily using their funds. They may not hold the Company liable for the instances when the cooperation process has not achieved the desired effect within their entire investment period.</li>
                        <li className='my-4'>6. The Company may change, modify or alter these T&Cs by taking into consideration the priority of the majority or the Company’s interests.</li>
                    </ol>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Rights and obligations of the Parties:</h3>

                    <ol>
                        <li className='my-4'>1. The Company must provide to the Client access to the uninterrupted and correctly working Website and information and Trading services in the online investment.</li>
                        <li className='my-4'>2. The Company must store in confidence any information provided by the Client and under no circumstances must disclose it to the third parties.</li>
                        <li className='my-4'>3. The Company must provide and continuously update if such necessity arises, the latest technological solution for maintaining secure Website use, data transfer and information exchange with the use of the Website.</li>
                        <li className='my-4'>4. The Company guarantees stable accumulation of the interest for the offers created for the Client.</li>
                        <li className='my-4'>5. The Client must keep their authentication details safe at all times and must not disclose them to any third party.</li>
                        <li className='my-4'>6. The Client must not use any SPAM technology or any other malware or spying programs.</li>
                        <li className='my-4'>7. The Client must remain loyal to the Company and guide themselves by taking informed and well-considered decisions. All disputes arising between the Company and the Client must be resolved by way of negotiation using communication channels available at the time of such disputes.</li>
                        <li className='my-4'>8. The Client must provide correct and valid details to the Company, including their payment details and government issued ID as a means of verification.</li>
                        <li className='my-4'>9. The Company has the right to manage assets provided voluntarily as investments funds with an assigned by the Client value in the form of deposits, created and activated for this purpose.</li>
                        <li className='my-4'>10. The Client has the right to use all Website functions, make investments, receive profit, receive profit in the way of a partner reward, and use features of the referral program.</li>
                        <li className='my-4'>11. THE COMPANY GUARANTEES 80% INSURANCE OF INVESTED FUNDS in respect to the cash back policy (CBP) should things go sideways. We are aware of the risks involved in these types of businesses and have been tested and are fully certified prepared.</li>
                    </ol>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Rights and obligations of the Parties:</h3>

                    <ol>
                        <li className='my-4'>1. The deposit with the validity term specified in the investment offer has not expired is considered an active deposit. All active deposits may not be withdrawn from the system before completion of their validity term.</li>
                        <li className='my-4'>2. Interest payouts on the investments made by the Client may only be processed through the payment system they used to create that investment or another system which is acceptable to the company.</li>
                        <li className='my-4'>3. Processing and payout of the funds may take up to 48 astronomical hours upon request.</li>
                        <li className='my-4'>4. The Client may not create multiple accounts using one email address for all accounts. In case the Company finds out that more than one account belongs to a single email address, we will block all personal accounts of that user.</li>
                    </ol>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Parties’ responsibilities:</h3>

                    <ol>
                        <li className='my-4'>1. The Company may not be held responsible for any issues related to the Website work if they have been caused by a force majeure or any other events beyond the Company’s control.</li>
                        <li className='my-4'>2. The Company may not be held responsible for the accuracy and correctness of how the Client perceives the information (content) presented on the Website. All information published on the Website is for information purposes only and may not be considered as guidance for any particular actions.</li>
                        <li className='my-4'>3. The Company may not be held responsible in any scenario where the Client had provided incorrect personal or payment details in their registration form or when the Client voluntarily entered those incorrect details themselves using the website user settings.</li>
                        <li className='my-4'>4. The Company may not be held responsible for any losses or inconveniences occurred as a result of a payment system malfunction which they use to process their investment or to withdraw funds.</li>
                        <li className='my-4'>5. The Company may not be held responsible for any losses sustained by the Client as a result of their website use and experience.</li>
                    </ol>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Prohibited Activities</h3>
                    <p className='my-4'>During the use of the Patricia Platform or Patricia Services and in the course of your interactions with Patricia, affiliated third parties or other Patricia users, you will not –</p>

                    <ol>
                        <li className='my-4'>1. Breach this Terms of Use, any policy and/or any agreement that you have agreed to with Patricia;</li>
                        <li className='my-4'>2. Breach any applicable laws and regulations;</li>
                        <li className='my-4'>3. Engage in or facilitate fraudulent activities;</li>
                        <li className='my-4'>4. Infringe on Patricia or any third party's copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy;</li>
                        <li className='my-4'>5. Sell counterfeit goods and/or product or service that is prohibited by law or is contrary to public order or good moral;</li>
                        <li className='my-4'>6. Act in a manner that is defamatory, trade libelous, threatening or harassing to Patricia, our employees, agents or other Patricia users;</li>
                        <li className='my-4'>7. Provide false, inaccurate or misleading Personal Information;</li>
                        <li className='my-4'>8. Engage in potentially fraudulent or suspicious activity and/or transactions;</li>
                        <li className='my-4'>9. Receive or attempt to receive chargeback from both Patricia and a Merchant, bank or card issuer for the same transaction during the course of a dispute;</li>
                        <li className='my-4'>10. Take any action that imposes an unreasonable or disproportionately large load on our infrastructure; and</li>
                        <li className='my-4'>11. Facilitate any viruses, trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or Personal Information;</li>
                    </ol>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Final provisions</h3>

                    <ol>
                        <li className='my-4'>1. The Client acknowledges that the investment process does not provide for obtaining identical results at different times.</li>
                        <li className='my-4'>2. The Company may suspend the Website in the event of a force majeure taking place at locations where either the Company or the Client are situated.</li>
                        <li className='my-4'>3. All changes, amendments or corrections to these Terms and Conditions are effective immediately after their entry into this publication.</li>
                        <li className='my-4'>4. All Website pages must be read in conjunction with these T&Cs as well as any provision, term or condition of these T&Cs which regulate the cooperation or investment process between the Parties.</li>
                    </ol>
                </div>
            </div>
        </div>
    </>
  )
}

export default TermsAndConditionComp;