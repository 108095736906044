import React, { useEffect, useState } from 'react';

//components
import Layout from '../../../shared/layout';
import AdminDashboard from '../../../components/admin-dashboard';
import UsersDashboard from '../../../components/users-dashboard';
import { getItem } from '../../../utils';

const Dashboard = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const { isLoggedIn, user } = getItem('auth');
    (isLoggedIn && (user.role === 'user')) ? setIsAdmin(false) : setIsAdmin(true); 
  }, []);

  return (
    <Layout>
      { isAdmin ? <AdminDashboard /> : <UsersDashboard /> }
    </Layout>
  )
}

export default Dashboard