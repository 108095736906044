import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import Card from "../../shared/card";
import { CONFIRM_DEPOSIT } from "../../services/deposits";
import initialImg from "../../assets/images/upload-img.png";

const ConfirmDepositComp = () => {
  const params = useParams();
  const { id }: any = params;

  const [idCard, setIdCard] = useState<any>();
  const [uploadImg, setUploadImg] = useState<any>(initialImg);
  const [loading, setLoading] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const openFile = () => {
    return fileRef.current?.click();
  };

  const onImageChange = (e: any) => {
    setIdCard(e.target.files[0]);
    // Assuming only image
    // const file = e.target.files[0];
    const reader = new FileReader();
    // const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUploadImg(reader.result);
    };
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleConfirmDeposit = () => {
    if (idCard) {
      setLoading(true);
      const formData = new FormData();
      formData.append("screenshot", idCard);

      CONFIRM_DEPOSIT(formData, id)
        .then((res) => {
          setLoading(false);
          const { message } = res.data;
          notify("success", message);
          // retrieveProfile()
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };

  return (
    <>
      <div className="mx-auto grid grid-cols-1 mt-4">
        <Card type="lg">
          {/* form */}
          <form className="">
            <div className="my-4">
              <div className="flex justify-center mb-4">
                <img
                  src={uploadImg}
                  width="150px"
                  onClick={() => openFile()}
                  height="150px"
                  className="hover:cursor-pointer"
                  alt="barcode"
                />
                <input
                  type="file"
                  ref={fileRef}
                  name="idCard"
                  multiple
                  accept="image/*"
                  onChange={(e) => onImageChange(e)}
                  className="hidden"
                />
              </div>
            </div>

            <div className="my-6">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleConfirmDeposit();
                }}
                className="py-3 text-center w-full rounded-3xl bg-[#FF971D] text-white"
              >
                {loading ? "Loading..." : "Confirm Deposit"}
              </button>
            </div>
          </form>
        </Card>
      </div>

      <ToastContainer />
    </>
  );
};

export default ConfirmDepositComp;
