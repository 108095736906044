import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// stylesheet
import "./style.css";
import { getItem } from "../../utils";

const VerifyEmailComp = () => {
  const url: any = process.env.REACT_APP_BASE_URL;

  // local States
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (code.value === "" || undefined || null) {
      isValid = false;
      setCode({ ...code, error: true });
    } else {
      setCode({ ...code, error: false });
    }
    return isValid;
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // handles Users Account Verification
  const handleVerifyEmail = () => {
    if (inputCheck()) {
      setLoading(true);
      const userId: string = getItem("clientId");
      console.log(userId, "ll");
      const data = { verificationCode: code.value };
      axios
        .post(`${url}/register/${userId}`, data)
        .then((res) => {
          setLoading(false);
          const { message } = res.data;
          notify("success", message);
          window.location.href = "/sign-in";
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };

  const handleCodeResend = () => {
    setLoading(true);
    const userId: string = getItem("clientId");
    axios
      .get(`${url}/register/sendmail/${userId}`)
      .then((res) => {
        setLoading(false);
        notify("success", "Verification Code has been sent to your email");
      })
      .catch((err) => {
        const { error } = err.response.data;
        setLoading(false);
        notify("error", error);
      });
  };

  return (
    <>
      <div className="wrapper pt-[5.7rem]">
        <div className="mx-auto w-11/12 sm:w-9/12 md:w-7/12 lg:w-[35%] h-max">
          <div className="bg-white w-full rounded-2xl shadow-lg py-16 px-10">
            <div className="">
              <h4 className="text-[#6A6A6A] text-xl font-bold text-center">
                Verify Account
              </h4>
              <p className="text-[#BFBFBF] text-sm my-3 text-center">
                Input the code sent to your email to verify.
              </p>
            </div>

            {/** Form Section */}
            <div id="form" className="mx-">
              <div className="my-6">
                <label htmlFor="code" className="text-[#BFBFBF] text-sm block">
                  Verification Code
                </label>
                <input
                  type="text"
                  name="code"
                  value={code.value}
                  onChange={(e) => setCode({ ...code, value: e.target.value })}
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    code.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-2 w-full`}
                />
              </div>

              <div className="my-3">
                <div>
                  <p className="text-[#BFBFBF]">
                    If you do not receive any code
                    <span
                      onClick={() => handleCodeResend()}
                      className="text-[#FF971D] text-sm mx-3 font-bold hover:cursor-pointer"
                    >
                      click here to resend
                    </span>
                  </p>
                </div>
              </div>

              <div className="my-3 text-center">
                <button
                  onClick={() => handleVerifyEmail()}
                  className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl "
                >
                  {loading ? "Loading..." : "Verify"}
                </button>
              </div>
            </div>
            {/** Form Section */}
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default VerifyEmailComp;
