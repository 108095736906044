import React from "react";

import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../shared/card";
import { setItem } from "../../utils";

type Props = {
  title: string;
  subTitle?: string;
  minAmount: number;
  percentage: number;
  duration: string;
  withdrawalAmount?: number;
};

const PlanCard = ({
  title,
  subTitle,
  minAmount,
  percentage,
  duration,
  withdrawalAmount,
}: Props) => {
  const handleSelectPlan = () => {
    setItem("plan", { title, minAmount });
  };

  return (
    <>
      <div className="w-full">
        <Card type="sm">
          <div className="py-6 px-3">
            <div>
              <h4 className="font-bold text-lg">{title} Plan</h4>
              {subTitle && <p className="text-[#6A6A6A] text-sm">{subTitle}</p>}
            </div>

            <div className="my-6">
              <div className="flex justify-start my-4">
                <div>
                  <BsCheckCircleFill className="text-[#EA3A60]" />
                </div>
                <div className="mx-3">Bitcoin</div>
              </div>
              <div className="flex justify-start my-4">
                <div>
                  <BsCheckCircleFill className="text-[#EA3A60]" />
                </div>
                <div className="mx-3">Etherum</div>
              </div>
              <div className="flex justify-start my-4">
                <div>
                  <BsCheckCircleFill className="text-[#EA3A60]" />
                </div>
                <div className="mx-3">Bitcoin Cash</div>
              </div>
              <div className="flex justify-start my-4">
                <div>
                  <BsCheckCircleFill className="text-[#EA3A60]" />
                </div>
                <div className="mx-3">Perfect Money</div>
              </div>
              <div className="flex justify-start my-4">
                <div>
                  <BsCheckCircleFill className="text-[#EA3A60]" />
                </div>
                <div className="mx-3 text-sm">
                  Minimum amount <span className="font-bold">${minAmount}</span>
                </div>
              </div>
              <div className="flex justify-start my-4">
                <div>
                  <BsCheckCircleFill className="text-[#EA3A60]" />
                </div>
                <div className="mx-3 text-sm">
                  Withdrawal at end of trade session{" "}
                  {withdrawalAmount && (
                    <span className="font-bold">${withdrawalAmount}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center">
              <div className="text-[#6A6A6A]">
                <span className="font-bold text-xl mr-2">{percentage}%</span>
                <span className="text-sm">
                  <small>{duration}</small>
                </span>
              </div>
              <div className="mt-5">
                <button
                  className="text-white bg-[#FF971D] py-1 px-8 rounded-2xl"
                  onClick={() => handleSelectPlan()}
                >
                  <Link to="/deposit">Invest</Link>
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PlanCard;
