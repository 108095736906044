import React from "react";
import { Link } from "react-router-dom";

//icons
import { BsPhone } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";

import logo from "../../assets/images/logo-white.png";

const Footer = () => {
  return (
    <>
      <div className="w-full bg-[#FF971D] text-white pt-16 px-8 pb-20">
        <div
          className="grid grid-cols-1 border-t-2 border-white mx-auto lg:w-9/12 space-x-2
                sm:grid-cols-2 
                md:grid-cols-3 
                lg:grid-cols-3"
        >
          <div className="pt-12">
            <img src={logo} alt="logo" />
          </div>
          <div>
            <div className="text-left">
              <h2 className="my-8 text-xl font-semibold">Our Information</h2>
              <p className="my-3 text-sm font-light hover:cursor-pointer">
                <Link to="/about-us">About Us</Link>
              </p>
              <p className="my-3 text-sm font-light hover:cursor-pointer">
                <Link to="/disclaimer">Disclaimer</Link>
              </p>
              <p className="my-3 text-sm font-light hover:cursor-pointer">
                <Link to="/refund-policy">Refund Policy</Link>
              </p>
              <p className="my-3 text-sm font-light hover:cursor-pointer">
                <Link to="/privacy">Privacy Policy</Link>
              </p>
              <p className="my-3 text-sm font-light hover:cursor-pointer">
                <Link to="/terms-and-conditions">Terms and conditions</Link>
              </p>
            </div>
          </div>
          <div>
            <div className="text-left">
              <h2 className="my-8 text-xl font-bold">Get in Touch</h2>
              <p className="my-4 text-sm font-light hover:cursor-pointer flex justify-start">
                <span className="mr-4">
                  <GoLocation />
                </span>
                <span>
                  2318 Rue Albert-Dion, Saint-Nicolas, QC G7A4Z4, Canada.
                </span>
              </p>
              <p className="my-4 text-sm font-light hover:cursor-pointer flex justify-start">
                <span className="mr-4">
                  <BsPhone />
                </span>
                <span>+1 (719) 401-1087</span>
              </p>
              <p className="my-4 text-sm font-light hover:cursor-pointer flex justify-start">
                <span className="mr-4">
                  <AiOutlineMail />
                </span>
                <span>support@smarthills.org</span>
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto lg:w-9/12 text-center text-white mt-16">
          <hr className="text-[#ffa845]" />
          <p className="mt-8 mb-2 text-sm font-light ">
            {" "}
            &copy; 2022 Smarthills. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
