import React from 'react'
import ForgotPasswordComp from '../../components/forgot-password';

const ForgotPassword = () => {
  return (
    <>
        <ForgotPasswordComp />
    </>
  )
}

export default ForgotPassword;