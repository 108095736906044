import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../shared/card';

function SkillSet() {
  return (
    <>
        <div className="bg-[#F9FAFC] w-full h-max py-8">
            <div className="mx-auto w-full lg:w-8/12 md:w-11/12 sm:w-11/12">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                    {/* card section */}
                    <div>
                        <div className="flex justify-between">
                            <div className='mt-28'>
                                <div className='my-8'>
                                    <Card type='sm'>
                                        <div className='text-center py-6 px-3 lg:px-6 lg:py-10'>
                                            <h1 className='text-[#28D1DC] text-3xl lg:text-6xl font-bold'>
                                                80k+
                                            </h1>
                                            <p className='text-sm text-[#0F2137]'>
                                                Successful <br />
                                                transactions
                                            </p>
                                        </div>
                                    </Card>
                                </div>

                                <div className='my-8'>
                                    <Card type='sm'>
                                        <div className='text-center py-6 px-3 lg:px-6 lg:py-10'>
                                            <h1 className='text-[#FA578E] text-3xl lg:text-6xl font-bold'>
                                                90+
                                            </h1>
                                            <p className='text-sm text-[#0F2137]'>
                                                Deicated Staff
                                            </p>
                                        </div>
                                    </Card>
                                </div>
                            </div>

                            <div className='ml-6 lg:ml-10 -mt-10'>
                                <div className='mt-28'>
                                    <div className='my-8'>
                                        <Card type='sm'>
                                            <div className='text-center p-3 lg:p-6 lg:py-10'>
                                                <h1 className='text-[#FF753A] text-3xl lg:text-6xl font-bold'>
                                                    150+
                                                </h1>
                                                <p className='text-sm text-[#0F2137]'>
                                                    Countries <br />
                                                    Available
                                                </p>
                                            </div>
                                        </Card>
                                    </div>

                                    <div className='my-8'>
                                        <Card type='sm'>
                                            <div className='text-center p-3 lg:p-6 lg:py-10'>
                                                <h1 className='text-[#28DCB2] text-3xl lg:text-6xl font-bold'>
                                                    & 3M
                                                </h1>
                                                <p className='text-sm text-[#0F2137]'>
                                                    Over $3million in <br /> payouts
                                                </p>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* card section */}

                    {/* card section */}
                    <div className='mx-auto w-10/12 sm:w-5/6 md:w-4/6 lg:w-2/6 mt-32'>
                        {/* <p className='text-[#FF971D] text-sm my-2'>CORE FEATURES</p> */}
                        <h3 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold text-[#0F2137]'>Our Trading and <br /> Investment <br /> Portfolio</h3>
                        <p className='text-[#02073E] mt-4 mb-8'>
                            Our delivery performance has been top-notch over the past few years, giving outstanding and superb results always. We give the best only.
                        </p>

                        <button className='bg-[#FF971D] text-white rounded-3xl text-thin px-6 py-1'>
                            <Link to="/sign-in">Get Started</Link>
                        </button>
                    </div>
                    {/* card section */}
                </div>
            </div>
        </div>
    </>
  )
}

export default SkillSet