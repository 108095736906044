import React from 'react';

type Props = {
    status: string;
}

const StatusBadge = ({status}: Props) => {

    const selectClass = (status: string = 'Pending'): string => {
        let style: string = '';
        if(status.toLowerCase() === 'approved'){
            style = 'bg-[#94FF92] text-[#2E782D] text-sm py-1 px-2 rounded-lg w-20'
        }
        if(status.toLowerCase() === 'pending'){
            style = 'bg-[#92D8FF] text-[#2D4B78] text-sm py-1 px-2 rounded-lg w-20'
        }
        if(status.toLowerCase() === 'failed'){
            style = 'bg-[#FF9F92] text-[#78312D] text-sm py-1 px-2 rounded-lg w-20'
        }
        return style;
    }

    return (
        <>
            <span className={ selectClass(status) }
            >
                { status }
            </span>
        </>
    )
}

export default StatusBadge;