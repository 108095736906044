import React from "react";
import FaqTile from "./faq-tile";

type FaqType = {
  title: string;
  content: string;
};

const Faq = () => {
  const faqs: FaqType[] = [
    {
      title: "How do I make payments?",
      content: `Payments are done by cryptocurrency. 
            You can deposit/invest by paying through one of USDT, Bitcoin.`,
    },
    {
      title: "How long does it take to receive my payment?",
      content: `Payments are remitted to your account within 24hours.`,
    },
    {
      title: "How can I verify my account?",
      content: `To verify your account, you need either a Drivers license or State Id. 
            If you have any of this, proceed to your profile page and upload the image of the document.`,
    },
    {
      title: "How do I make withdrawals?",
      content: `To withdraw your earnings, you have to be verified. 
            When you are sure that you are verified and have earnings balance, proceed to the withdrawal page and request withdrawal. After 24 hours, your earnings will be remitted to your wallet.`,
    },
  ];

  return (
    <>
      <div id="faq" className="w-full mt-16">
        <div className="mx-auto w-10/12 sm:w-9/12 md:w-7/12 lg:w-6/12">
          <div className="text-center mb-6">
            <p className="text-[#244886] font-normal my-2">Frequent Question</p>
            <h3 className="text-xl sm:text-xl md:text-2xl lg:text-2xl font-bold text-[#0F2137]">
              Do you have any question
            </h3>
          </div>

          {/* faqs */}
          {faqs &&
            faqs.map((item: FaqType, key: number) => {
              return (
                <FaqTile key={key} title={item.title} content={item.content} />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Faq;
