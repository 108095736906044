import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import axios from "axios";

import Card from "../../shared/card";
import { WithDraw } from "../../common";
import StatusBadge from "../../shared/status-badge";
import { RETRIEVE_USERS_WITHDRAWALS } from "../../services/withdraws";

const WithdrawComp = () => {
  const url: any = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState<boolean>(false);
  const [currencyName, setCurrencyName] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });
  const [walletAddress, setWalletAddress] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });
  const [amount, setAmount] = useState<{ value: number; error: boolean }>({
    value: 0,
    error: false,
  });
  const [withdraws, setWithdraws] = useState<WithDraw[]>([]);

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (currencyName.value === "" || undefined || null) {
      isValid = false;
      setCurrencyName({ ...currencyName, error: true });
    } else {
      setCurrencyName({ ...currencyName, error: false });
    }
    if (amount.value === undefined || null) {
      isValid = false;
      setAmount({ ...amount, error: true });
    } else {
      setAmount({ ...amount, error: false });
    }
    if (walletAddress.value === undefined || null) {
      isValid = false;
      setWalletAddress({ ...walletAddress, error: true });
    } else {
      setWalletAddress({ ...walletAddress, error: false });
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (inputCheck()) {
      setLoading(true);
      const data = {
        amount: amount.value,
        currencyName: currencyName.value,
        walletAddress: walletAddress.value,
      };
      axios
        .post(`${url}/create-withdrawal`, data)
        .then((res) => {
          setLoading(false);
          const { message } = res.data;
          notify("success", message);
          retrieveWithdrawals();
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveWithdrawals = () => {
    setLoading(true);
    RETRIEVE_USERS_WITHDRAWALS()
      .then((res) => {
        const { message, data } = res.data;
        notify("success", message);
        setWithdraws(data);
        setLoading(false);
      })
      .catch((err) => {
        const { error } = err.response.data;
        setLoading(false);
        notify("error", error);
      });
  };

  useEffect(() => {
    retrieveWithdrawals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto grid grid-cols-1 sm:w-10/12 md:w-10/12 lg:w-9/12 my-8">
        <Card type="lg">
          <div className="mx-auto w-full md:w-11/12 lg:w-10/12">
            <div className="">
              <h2 className="text-xl sm:text-xl md:text-2xl lg:text-3xl font-medium">
                Withdraw Crypto
              </h2>
            </div>
            <form>
              <div className="my-8">
                <select
                  name="currency"
                  id="currency"
                  onChange={(e) =>
                    setCurrencyName({ ...currencyName, value: e.target.value })
                  }
                  className="w-full px-5 py-3 text-[#9e9e9e] border-2 border-[#9e9e9e] rounded-3xl focus:border-[#FF971D] "
                >
                  <option value="">Currency</option>
                  <option value="Bitcoin">Bitcoin</option>
                  <option value="Ethereum">Ethereum</option>
                  <option value="Usdt">Usdt</option>
                </select>
              </div>
              <div className="my-8">
                <input
                  type="text"
                  placeholder="Wallet Address"
                  value={walletAddress.value}
                  onChange={(e) =>
                    setWalletAddress({
                      ...walletAddress,
                      value: e.target.value,
                    })
                  }
                  className="w-full px-5 py-3 text-[#9e9e9e] border-2 border-[#9e9e9e] rounded-3xl focus:border-[#FF971D] "
                />
              </div>
              <div className="my-8">
                <input
                  type="number"
                  placeholder="Amount"
                  value={amount.value}
                  onChange={(e) =>
                    setAmount({ ...amount, value: parseInt(e.target.value) })
                  }
                  className="w-full px-5 py-3 text-[#9e9e9e] border-2 border-[#9e9e9e] rounded-3xl focus:border-[#FF971D] active:border-[#FF971D]"
                />
              </div>
              <div className="my-8">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="bg-[#FF971D] text-white text-xl font-medium w-full rounded-[2rem] py-4 text-center"
                >
                  {loading ? "Loading..." : "Make Withdrawal"}
                </button>
              </div>
            </form>
          </div>
        </Card>

        <div className="my-8">
          <Card type="lg">
            <div className="overflow-scroll">
              <table className="table table-auto w-full mx-auto border-spacing-y-4">
                <thead>
                  <tr className="border-spacing-y-4">
                    <th className="table-caption text-left">Email</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {withdraws.length > 0 ? (
                    withdraws.map((item: WithDraw, key: number) => {
                      return (
                        <tr key={key} className="my-4">
                          <td className="text-left border-spacing-y-4">
                            {item.firstname}
                          </td>
                          <td className="text-center py-3">${item.amount}</td>
                          <td className="text-center py-3">
                            {moment(item?.createdAt).format("MM-DD-YYYY")}
                          </td>
                          <td className="text-center py-3">
                            <StatusBadge status={item.status} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center py-3">
                        No Withdrawals available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default WithdrawComp;
