import React from 'react';

//components
import Layout from '../../../shared/layout';
import TransactionDetailComp from '../../../components/admin-dashboard/transaction-detail-comp';

const TransactionDetailPage = () => {
  return (
    <>
        <Layout>
            <TransactionDetailComp />
        </Layout>
    </>
  )
}

export default TransactionDetailPage;