import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../services/config";

const ChangePassword = () => {
  const url: any = process.env.REACT_APP_BASE_URL;

  // local States
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [newPassword, setNewPassword] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });
  const [confirmPassword, setConfirmPassword] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (password.value === "" || undefined || null) {
      isValid = false;
      setPassword({ ...password, error: true });
    } else {
      setPassword({ ...password, error: false });
    }
    if (newPassword.value === "" || undefined || null) {
      isValid = false;
      setNewPassword({ ...newPassword, error: true });
    } else {
      setNewPassword({ ...newPassword, error: false });
    }
    if (confirmPassword.value === "" || undefined || null) {
      isValid = false;
      setConfirmPassword({ ...confirmPassword, error: true });
    } else {
      setConfirmPassword({ ...confirmPassword, error: false });
    }
    return isValid;
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // handles login
  const handlePasswordReset = () => {
    if (inputCheck()) {
      setLoading(true);
      const data = {
        password: password.value,
        confirm_password: confirmPassword.value,
        new_password: newPassword.value,
      };
      axios
        .post(`${url}/change-password`, data)
        .then((res) => {
          setLoading(false);
          notify("success", "Your password successfully changed");
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };

  return (
    <>
      {/** Form Section */}
      <div id="form" className="mx-">
        <div className="my-6">
          <label htmlFor="password" className="text-[#BFBFBF] text-sm block">
            Previous Password
          </label>
          <input
            type="text"
            name="password"
            value={password.value}
            onChange={(e) =>
              setPassword({ ...password, value: e.target.value })
            }
            className={`bg-white text-[#6A6A6A] border-2 ${
              password.error ? "border-red-500" : "border-[#BFBFBF]"
            } rounded-md px-4 py-2 w-full`}
          />
        </div>

        <div className="my-6">
          <label htmlFor="newPassword" className="text-[#BFBFBF] text-sm block">
            New Password
          </label>
          <input
            type="password"
            name="newPassword"
            value={newPassword.value}
            onChange={(e) =>
              setNewPassword({ ...newPassword, value: e.target.value })
            }
            className={`bg-white text-[#6A6A6A] border-2 ${
              newPassword.error ? "border-red-500" : "border-[#BFBFBF]"
            } rounded-md px-4 py-2 w-full`}
          />
        </div>

        <div className="my-6">
          <label
            htmlFor="confirmPassword"
            className="text-[#BFBFBF] text-sm block"
          >
            Confirm Password
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword.value}
            onChange={(e) =>
              setConfirmPassword({ ...confirmPassword, value: e.target.value })
            }
            className={`bg-white text-[#6A6A6A] border-2 ${
              confirmPassword.error ? "border-red-500" : "border-[#BFBFBF]"
            } rounded-md px-4 py-2 w-full`}
          />
        </div>

        <div className="my-3 text-center">
          <button
            onClick={() => handlePasswordReset()}
            className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl "
          >
            {loading ? "Loading..." : "Change Password"}
          </button>
        </div>
      </div>
      {/** Form Section */}

      <ToastContainer />
    </>
  );
};

export default ChangePassword;
