import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import Card from "../../shared/card";
import { Transaction } from "../../common";
import StatusBadge from "../../shared/status-badge";
import { RETRIEVE_USERS_TRANSACTIONS } from "../../services/transactions";
import { RETRIEVE_USER_PROFILE } from "../../services";
import { numConverter } from "../../utils/numConverter";

const UsersDashboard = () => {
  const [balance, setBalance] = useState<number>(0);
  const [earnings, setEarnings] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveTransaction = () => {
    RETRIEVE_USERS_TRANSACTIONS()
      .then((res) => {
        const { data } = res.data;
        notify("success", `${data.transactions.length} transactions found`);
        setTransactions(data.transactions);
      })
      .catch((err) => {
        const { error } = err.response.data;
        notify("error", error);
      });
  };

  const retrieveProfile = () => {
    RETRIEVE_USER_PROFILE()
      .then((res) => {
        const { data } = res.data;
        setBalance(data.balance);
        setEarnings(data.earnings);

        setTotal(Number(data.balance || 0) + Number(data.earnings || 0));
      })
      .catch((err) => {
        const { error } = err.response.data;
        console.log(error);
      });
  };

  useEffect(() => {
    retrieveProfile();
    retrieveTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* display cards */}
      <div
        className="grid grid-cols-1 space-y-2
          sm:grid-cols-2 sm:space-y-0 sm:space-x-6
          md:grid-cols-3 md:space-y-0 md:space-x-6
          lg:grid-cols-3 lg:space-y-0 lg:space-x-6"
      >
        <div className="">
          <Card type="lg">
            <div className="text-center py-6 lg:py-10">
              <h5 className="my-4 text-sm">Your Deposit</h5>
              <h1 className="text-[#6A6A6A] text-3xl lg:text-6xl font-bold">
                {numConverter(balance, null)}
              </h1>
            </div>
          </Card>
        </div>

        <div className="">
          <Card type="lg">
            <div className="text-center py-6 lg:py-10">
              <h5 className="my-4 text-sm">Earnings</h5>
              <h1 className="text-[#6A6A6A] text-3xl lg:text-6xl font-bold">
                {numConverter(earnings, null)}
              </h1>
            </div>
          </Card>
        </div>

        <div className="">
          <Card type="lg">
            <div className="text-center py-6 lg:py-10">
              <h5 className="my-4 text-sm">Total</h5>
              <h1 className="text-[#6A6A6A] text-3xl lg:text-6xl font-bold">
                {numConverter(total, null)}
              </h1>
            </div>
          </Card>
        </div>
      </div>
      {/* display cards */}

      {/* Transacations */}
      <div className="my-8 grid grid-cols-1">
        <div>
          <Card type="lg">
            {/* recent transactions */}
            <div className="flex justify-between">
              <div>
                <h3 className="text-2xl font-medium">Recent Transactions</h3>
              </div>

              {/* calendar */}
              <div className="group"></div>
            </div>

            <div className="overflow-scroll">
              <table className="table table-auto w-full mx-auto border-spacing-y-4">
                <thead>
                  <tr className="border-spacing-y-4">
                    <th className="table-caption text-left">Currency</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {transactions.length > 0 ? (
                    transactions.map((item: Transaction, key: number) => {
                      return (
                        <tr key={key} className="my-4">
                          <td className="text-left border-spacing-y-4">
                            {item.currency}
                          </td>
                          <td className="text-center capitalize py-3">
                            {item.transactionType}
                          </td>
                          <td className="text-center py-3">${item.amount}</td>
                          <td className="text-center py-3">
                            {moment(item?.createdAt).format("MM-DD-YYYY")}
                          </td>
                          <td className="text-center py-3">
                            <StatusBadge status={item.status} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center py-3">
                        No Transactions
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default UsersDashboard;
