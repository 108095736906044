import React from 'react'
import Layout from '../../../shared/layout'
import ConfirmDepositComp from '../../../components/users-dashboard/confirm-deposit-comp'

function ConfirmDeposit() {
    return (
        <>
            <Layout>
                <ConfirmDepositComp />
            </Layout>
        </>
    )
}

export default ConfirmDeposit