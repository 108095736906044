import React from "react";
import PlanCard from "../../../components/plan-card";

// components
import Layout from "../../../shared/layout";

const PricingPlan = () => {
  return (
    <Layout>
      <div className="text-center w-11/12">
        <p className="text-sm text-[#FF971D] my-4">PRICING PLAN</p>
        <h3 className="text-xl lg:text-2xl">Choose your pricing policy</h3>
      </div>

      {/* plan cards */}
      <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:w-9/12 my-8">
        <div
          className="grid grid-cols-1 space-y-3
          sm:grid-cols-2 
          md:grid-cols-2 md:space-x-3 md:space-y-1
          lg:grid-cols-3 lg:space-x-3 lg:space-y-0"
        >
          <div className="">
            <PlanCard
              title="Regular"
              percentage={15}
              duration="roi in 48 hours"
              subTitle="For Small teams"
              minAmount={500}
            />
          </div>

          <div className="">
            <PlanCard
              title="Bronze"
              percentage={30}
              duration="roi in 24 hours"
              subTitle="For Small teams or office"
              minAmount={7000}
            />
          </div>

          <div className="">
            <PlanCard
              title="Gold"
              percentage={45}
              duration="roi in 24 hours "
              subTitle="For Small teams or office"
              minAmount={15000}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPlan;
