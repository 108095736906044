import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//icons
import home from "../../assets/icons/dash.png";
import homeActive from "../../assets/icons/dash-active.png";
import dpt from "../../assets/icons/dpt.png";
import dptActive from "../../assets/icons/dpt-active.png";
import planImg from "../../assets/icons/planicon.png";
import planActive from "../../assets/icons/planicon-active.png";
import wtd from "../../assets/icons/wtd.png";
import wtdActive from "../../assets/icons/wtd-active.png";
import usersIcon from "../../assets/icons/usersIcon.png";
import usersIconActive from "../../assets/icons/usersIcon-active.png";
import { getItem } from "../../utils";

type Props = {
  sidebarMenus?: any[];
};

const Sidebar = ({ sidebarMenus }: Props) => {
  const location = useLocation();
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    const { user } = getItem("auth");
    if (user && user.role) setRole(user.role);
  }, []);

  return (
    <>
      <div className="bg-white min-h-screen max-h-fit px-4 py-5">
        <ul className="list-none">
          <li className="my-6 py-3 px-4 text-center" title="Dashboard">
            <Link to="/dashboard">
              {location.pathname === "/dashboard" ? (
                <img
                  src={homeActive}
                  alt="dashboard"
                  width="30px"
                  height="30px"
                />
              ) : (
                <img src={home} alt="dashboard" width="30px" height="30px" />
              )}
            </Link>
          </li>

          {role === "user" && (
            <li className="my-6 py-3 px-4 text-center" title="Pricing Plan">
              <Link to="/pricing-plan">
                {location.pathname === "/pricing-plan" ? (
                  <img
                    src={planActive}
                    alt="pricing-plan"
                    width="30px"
                    height="30px"
                  />
                ) : (
                  <img
                    src={planImg}
                    alt="pricing-plan"
                    width="30px"
                    height="30px"
                  />
                )}
              </Link>
            </li>
          )}

          <li className="my-6 py-3 px-4 text-center" title="Deposit">
            <Link to="/deposit">
              {location.pathname === "/deposit" ? (
                <img src={dptActive} alt="deposit" width="30px" height="30px" />
              ) : (
                <img src={dpt} alt="deposit" width="30px" height="30px" />
              )}
            </Link>
          </li>

          <li className="my-6 py-3 px-4 text-center" title="Withdraw">
            <Link to="/withdraw">
              {location.pathname === "/withdraw" ? (
                <img
                  src={wtdActive}
                  alt="withdraw"
                  width="30px"
                  height="30px"
                />
              ) : (
                <img src={wtd} alt="withdraw" width="30px" height="30px" />
              )}
            </Link>
          </li>

          {role !== "user" && (
            <li className="my-6 py-3 px-4 text-center" title="Users">
              <Link to="/users">
                {location.pathname === "/users" ? (
                  <img
                    src={usersIconActive}
                    alt="users"
                    width="30px"
                    height="30px"
                  />
                ) : (
                  <img src={usersIcon} alt="users" width="30px" height="30px" />
                )}
              </Link>
            </li>
          )}

          {role === "user" && (
            <li className="my-6 py-3 px-4 text-center" title="My Referrals">
              <Link to="/referrals">
                {location.pathname === "/referrals" ? (
                  <img
                    src={usersIconActive}
                    alt="pricing-plan"
                    width="30px"
                    height="30px"
                  />
                ) : (
                  <img
                    src={usersIcon}
                    alt="pricing-plan"
                    width="30px"
                    height="30px"
                  />
                )}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
