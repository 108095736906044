import React, { useState, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

//icons
import profile from "../../assets/images/profile.png";
import home from "../../assets/icons/dash.png";
import homeActive from "../../assets/icons/dash-active.png";
import dpt from "../../assets/icons/dpt.png";
import dptActive from "../../assets/icons/dpt-active.png";
import planImg from "../../assets/icons/planicon.png";
import planActive from "../../assets/icons/planicon-active.png";
import wtd from "../../assets/icons/wtd.png";
import wtdActive from "../../assets/icons/wtd-active.png";
import usersIcon from "../../assets/icons/usersIcon.png";
import usersIconActive from "../../assets/icons/usersIcon-active.png";
import { getItem } from "../../utils";
import { RETRIEVE_USER_PROFILE } from "../../services";

const Navbar = () => {
  const location = useLocation();
  const [role, setRole] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [showSideBar, setShowSidebar] = useState<boolean>(false);

  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => setShowSidebar(false);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("clientId");
    localStorage.removeItem("clientToken");
    localStorage.removeItem("plan");
    window.location.href = "/sign-in";
  };

  const retrieveProfile = () => {
    RETRIEVE_USER_PROFILE()
      .then((res) => {
        const { data } = res.data;
        setUsername(data.lastname);
      })
      .catch((err) => {
        const { error } = err.response.data;
        console.log(error);
      });
  };

  useEffect(() => {
    const { user } = getItem("auth");
    if (user && user.role) {
      setRole(user.role);
    }
  }, []);

  useEffect(() => {
    retrieveProfile();
  }, []);

  return (
    <>
      <div className="flex justify-between py-5">
        <div
          className="block sm:hidden md:hidden lg:hidden pt-3"
          onClick={() => openSidebar()}
        >
          <FiMenu />
        </div>

        <div>
          <h3 className="text-lg sm:text-lg md:text-xl lg:text-xl mt-2">
            Welcome <span className="font-bold">{username}</span>
          </h3>
        </div>

        <div className="flex items-center">
          <div
            className="mx-4 sm:mx-5 md:mx-7 lg:mx-8 inline-flex cursor-pointer"
            title="Log out"
          >
            <span onClick={() => handleLogout()}>
              <BiLogOut className="text-2xl font-medium" />
            </span>
          </div>
          <div
            className="inline-flex bg-black rounded-full overflow-hidden"
            title="Profile"
          >
            <Link to="/profile">
              <img
                src={profile}
                width="40px"
                height="40px"
                className=" cursor-pointer shadow-sm"
                alt="profile"
              />
            </Link>
          </div>
        </div>
      </div>
      {/* style={customeStyle.sidebar} */}

      <div
        className={`${
          showSideBar ? "block" : "hidden"
        } absolute left-0 top-0 bottom-0 h-full
            bg-[#969696a8] text-left w-full px-8 py-4 z-200`}
      >
        <div
          style={{ zIndex: 250 }}
          className={`
            absolute left-0 top-0 bottom-0 h-full
            bg-white text-left w-8/12 px-8 py-4 z-200
            `}
        >
          <div className="bg-white">
            <div className="container text-right">
              <button
                className="text-black text-xl"
                onClick={() => closeSidebar()}
              >
                <FaTimes />
              </button>

              <ul className="list-none">
                <li className="my-6 py-3 px-4 text-left">
                  <Link to="/dashboard" className="inline-flex">
                    {location.pathname === "/dashboard" ? (
                      <img
                        src={homeActive}
                        alt="dashboard"
                        width="20px"
                        height="20px"
                      />
                    ) : (
                      <img
                        src={home}
                        alt="dashboard"
                        width="25px"
                        height="25px"
                      />
                    )}
                  </Link>
                  <span className="inline-flex mx-2">Dashboard</span>
                </li>

                {role === "user" && (
                  <li className="my-6 py-3 px-4 text-left">
                    <Link to="/pricing-plan" className="inline-flex">
                      {location.pathname === "/pricing-plan" ? (
                        <img
                          src={planActive}
                          alt="pricing-plan"
                          width="25px"
                          height="25px"
                        />
                      ) : (
                        <img
                          src={planImg}
                          alt="pricing-plan"
                          width="25px"
                          height="25px"
                        />
                      )}
                      <span className="inline-flex mx-2">Plans</span>
                    </Link>
                  </li>
                )}

                <li className="my-6 py-3 px-4 text-left">
                  <Link to="/deposit" className="inline-flex">
                    {location.pathname === "/deposit" ? (
                      <img
                        src={dptActive}
                        alt="deposit"
                        width="25px"
                        height="25px"
                      />
                    ) : (
                      <img src={dpt} alt="deposit" width="25px" height="25px" />
                    )}
                    <span className="inline-flex mx-2">Deposits</span>
                  </Link>
                </li>

                <li className="my-6 py-3 px-4 text-left">
                  <Link to="/withdraw" className="inline-flex">
                    {location.pathname === "/withdraw" ? (
                      <img
                        src={wtdActive}
                        alt="withdraw"
                        width="25px"
                        height="25px"
                      />
                    ) : (
                      <img
                        src={wtd}
                        alt="withdraw"
                        width="25px"
                        height="25px"
                      />
                    )}
                    <span className="inline-flex mx-2">Withdrawals</span>
                  </Link>
                </li>

                {role !== "user" && (
                  <li className="my-6 py-3 px-4 text-left">
                    <Link to="/users" className="inline-flex">
                      {location.pathname === "/users" ? (
                        <img
                          src={usersIconActive}
                          alt="users"
                          width="25px"
                          height="25px"
                        />
                      ) : (
                        <img
                          src={usersIcon}
                          alt="users"
                          width="25px"
                          height="25px"
                        />
                      )}
                      <span className="inline-flex mx-2">Users</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
