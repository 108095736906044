import React, { useEffect } from "react";
import axios from 'axios';
import { TickerTape } from "react-tradingview-embed";
// components
import Faq from "../../components/home-components/faq";
import Footer from "../../components/home-components/footer";
import Payment from "../../components/home-components/payment";
import Clients from "../../components/home-components/clients";
import Features from "../../components/home-components/features";
import HeroComp from "../../components/home-components/hero-comp";
import SkillSet from "../../components/home-components/skill-set";
import AdvancedFeatures from "../../components/home-components/advanced-features";

export default function HomePage() {

  const pingBackend = () => {
    const url: any = process.env.REACT_APP_BASE_URL;
    axios.get(url).then(res => {
      console.log('connect');
    }).catch(err => {
      console.log('Error connection');
    })
  }

  useEffect(() => {
    pingBackend();
  })
  
  return (
    <>
      <HeroComp />
      <TickerTape widgetProps={{ colorTheme: "light" }} />
      <Features />
      <AdvancedFeatures />
      <SkillSet />
      <Clients />
      <Payment />
      <Faq />
      <Footer />
    </>
  );
}
