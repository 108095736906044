import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import moment from "moment";

//icons
import { AiOutlineArrowUp } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { WithDraw } from "../../common";
import { UPDATE_WITHDRAWALS } from "../../services/withdraws";

//shared components
import Card from "../../shared/card";
import StatusBadge from "../../shared/status-badge";
import { sortArray } from "../../utils";
import { RETRIEVE_ALL_WITHDRAWALS } from "../../services";

const AdminWithdraw = () => {
  const [withdraws, setWithdraws] = useState<WithDraw[]>([]);

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveTransaction = () => {
    RETRIEVE_ALL_WITHDRAWALS()
      .then((res) => {
        const { data } = res.data;
        notify("success", `${data.length} withdrawal records found`);
        setWithdraws(data);
      })
      .catch((err) => {
        const { error } = err.response.data;
        notify("error", error);
      });
  };

  const handleWithdrawalUpdate = (id: string, status: string) => {
    const data = { id, status };
    UPDATE_WITHDRAWALS(data)
      .then((res) => {
        // const { message, data } = res.data;
        retrieveTransaction();
        notify("success", `Withdrawal is ${status}d`);
      })
      .catch((err) => {
        const { error } = err.response.data;
        notify("error", error);
      });
  };

  const sortData = (field: string) => {
    const sortedArray: any[] = sortArray(withdraws, field);
    if (sortedArray.length > 0) {
      setWithdraws(sortedArray);
    }
  };

  useEffect(() => {
    retrieveTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Transacations */}
      <div className="my-8 grid grid-cols-1">
        <div>
          <Card type="lg">
            {/* recent transactions */}
            <div className="flex justify-between mb-6">
              <div>
                <h3 className="text-2xl font-medium">Withdraws</h3>
              </div>

              {/* calendar */}
              <div className="group">
                <div
                  className="relative mx-1 px-1 py-2 group  mb-1 md:mb-0"
                  id="button_pm"
                >
                  {/* <span className="firstlevel hover:text-red-500 whitespace-no-wrap text-gray-600 hover:text-blue-800"
                                >
                                    <BiEditAlt className="text-blue hover:cursor-pointer inline" />
                                </span> */}
                  <div
                    className="bg-[#494949] text-white py-0 lg:py-1 px-2 lg:px-3
                                rounded-l-none rounded-r-3xl rounded-b-3xl hover:cursor-pointer inline-block"
                  >
                    <span className="mx-2 inline text-sm">Sort by</span>
                    <AiOutlineArrowUp className="text-white inline-block" />
                  </div>
                  <ul className=" absolute left-0 top-0 mt-10 p-2 rounded-lg shadow-lg bg-[#F6F6F6] z-0 hidden group-hover:block">
                    <svg
                      className="block fill-current text-[#F6F6F6] w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                    </svg>
                    <li className="hover:bg-[#FF971D] hover:cursor-pointer pr-10 p-1 whitespace-no-wrap hover:text-white rounded-md text-sm md:text-base ">
                      <span
                        className="items-left px-2 py-3"
                        onClick={() => sortData("createdAt")}
                      >
                        By Date
                      </span>
                    </li>
                    <li className="hover:bg-[#FF971D] hover:cursor-pointer pr-10 p-1 whitespace-no-wrap rounded-md hover:text-white text-sm md:text-base ">
                      <span
                        className="items-left px-2 py-2"
                        onClick={() => sortData("firstname")}
                      >
                        Alphabetically
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="overflow-scroll">
              <table className="table table-auto w-full mx-auto border-spacing-y-4">
                <thead>
                  <tr className="border-spacing-y-4">
                    <th className="table-caption text-left">Email</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {withdraws.length > 0 ? (
                    withdraws.map((item: WithDraw, key: number) => {
                      return (
                        <tr key={key} className="my-4">
                          <td className="text-left border-spacing-y-4">
                            {item.firstname}
                          </td>
                          <td className="text-center py-3">${item.amount}</td>
                          <td className="text-center py-3">
                            {moment(item?.createdAt).format("MM-DD-YYYY")}
                          </td>
                          <td className="text-center py-3">
                            <StatusBadge status={item.status} />
                          </td>
                          <td className="text-center py-3">
                            <div
                              className="relative mx-1 px-1 py-2 group  mb-1 md:mb-0"
                              id="button_pm"
                            >
                              <span className="firstlevel hover:text-red-500 whitespace-no-wrap text-gray-600 hover:text-blue-800">
                                <BiEditAlt className="text-blue hover:cursor-pointer inline" />
                              </span>
                              <ul className="w-max absolute left-0 top-0 mt-10 p-2 rounded-lg shadow-lg bg-[#F6F6F6] z-10 hidden group-hover:block">
                                <svg
                                  className="block fill-current text-[#F6F6F6] w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                </svg>
                                <li className="hover:bg-[#FF971D] hover:cursor-pointer pr-10 p-1 whitespace-no-wrap hover:text-white rounded-md text-sm md:text-base ">
                                  <span
                                    className="items-left px-2 py-3"
                                    onClick={() =>
                                      handleWithdrawalUpdate(
                                        item._id,
                                        "approve"
                                      )
                                    }
                                  >
                                    Approve
                                  </span>
                                </li>
                                <li className="hover:bg-[#FF971D] hover:cursor-pointer pr-10 p-1 whitespace-no-wrap rounded-md hover:text-white text-sm md:text-base ">
                                  <span
                                    className="items-left px-2 py-2"
                                    onClick={() =>
                                      handleWithdrawalUpdate(
                                        item._id,
                                        "decline"
                                      )
                                    }
                                  >
                                    Decline
                                  </span>
                                </li>
                                <li className="hover:bg-[#FF971D] hover:cursor-pointer pr-10 p-1 whitespace-no-wrap rounded-md hover:text-white text-sm md:text-base ">
                                  <span className="items-left px-2 py-2">
                                    <Link to={`/transaction/${item._id}`}>
                                      View Details
                                    </Link>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center py-3">
                        No Withdrawals available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default AdminWithdraw;
