import React from 'react';
import RefundPolicyComp from '../../components/refund-policy-comp';

const RefundPolicy = () => {
    return(
        <>
            <RefundPolicyComp />
        </>
    )
}

export default RefundPolicy;