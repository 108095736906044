import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import logo from "../../assets/images/logo-yellow.png";

const AdminRegisterComp = () => {
  const url: any = process.env.REACT_APP_BASE_URL;
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<{ value: string; error: boolean }>(
    { value: "", error: false }
  );
  const [lastName, setLastName] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [email, setEmail] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [phone, setPhone] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [password, setPassword] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [confirmPassword, setConfirmPassword] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (firstName.value === "" || undefined || null) {
      isValid = false;
      setFirstName({ ...firstName, error: true });
    } else {
      setFirstName({ ...firstName, error: false });
    }

    if (lastName.value === "" || undefined || null) {
      isValid = false;
      setLastName({ ...lastName, error: true });
    } else {
      setLastName({ ...lastName, error: false });
    }

    if (email.value === "" || undefined || null) {
      isValid = false;
      setEmail({ ...email, error: true });
    } else {
      setEmail({ ...email, error: false });
    }

    if (phone.value === "" || undefined || null) {
      isValid = false;
      setPhone({ ...phone, error: true });
    } else {
      setPhone({ ...phone, error: false });
    }

    if (password.value === "" || undefined || null) {
      isValid = false;
      setPassword({ ...password, error: true });
    } else {
      setPassword({ ...password, error: false });
    }

    if (confirmPassword.value === "" || undefined || null) {
      isValid = false;
      setConfirmPassword({ ...confirmPassword, error: true });
    } else {
      setConfirmPassword({ ...confirmPassword, error: false });
    }
    return isValid;
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSignUp = () => {
    if (inputCheck()) {
      setLoading(true);
      const data = {
        firstname: firstName.value,
        lastname: lastName.value,
        phone: phone.value,
        email: email.value,
        password: password.value,
        confirm_password: confirmPassword.value,
      };
      axios
        .post(`${url}/register?regAdmin=true`, data)
        .then((res) => {
          setLoading(false);
          notify("success", "Admin Sign up successful");
          window.location.href = "/verify-email";
        })
        .catch((err) => {
          setLoading(false);
          const { error } = err.response.data;
          notify("error", error);
        });
    }
  };

  return (
    <>
      <div className="wrapper pt-[5.7rem]">
        <div className="mx-auto w-11/12 sm:w-9/12 md:w-7/12 lg:w-6/12 h-max">
          <div className="flex justify-center mb-4">
            <div className="">
              <img src={logo} width="100px" height="100px" alt="barcode" />
            </div>
          </div>

          <div className="bg-white w-full rounded-2xl shadow-lg py-10 px-10">
            <div className="">
              <h4 className="text-[#6A6A6A] text-xl font-bold text-center">
                Admin Sign up
              </h4>
              <p className="text-[#BFBFBF] text-sm text-center my-3">
                Fill in your details to get started
              </p>
            </div>

            {/** Form Section */}
            <div
              id="form"
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2"
            >
              <div className="my-3 mx-1">
                <label
                  htmlFor="firstName"
                  className="text-[#BFBFBF] text-sm block"
                >
                  First Name*
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={firstName.value}
                  onChange={(e) =>
                    setFirstName({ ...firstName, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    email.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-1 w-full`}
                />
              </div>

              <div className="my-3 mx-1">
                <label
                  htmlFor="lastName"
                  className="text-[#BFBFBF] text-sm block"
                >
                  Last Name*
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={lastName.value}
                  onChange={(e) =>
                    setLastName({ ...lastName, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    lastName.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-1 w-full`}
                />
              </div>

              <div className="my-3 mx-1">
                <label htmlFor="email" className="text-[#BFBFBF] text-sm block">
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  value={email.value}
                  onChange={(e) =>
                    setEmail({ ...email, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    email.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-1 w-full`}
                />
              </div>

              <div className="my-3 mx-1">
                <label htmlFor="phone" className="text-[#BFBFBF] text-sm block">
                  Phone No*
                </label>
                <input
                  type="tel"
                  value={phone.value}
                  onChange={(e) =>
                    setPhone({ ...phone, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    phone.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-1 w-full`}
                />
              </div>

              <div className="my-3 mx-1">
                <label
                  htmlFor="password"
                  className="text-[#BFBFBF] text-sm block"
                >
                  Password*
                </label>
                <input
                  type="password"
                  value={password.value}
                  onChange={(e) =>
                    setPassword({ ...password, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    password.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-1 w-full`}
                />
              </div>

              <div className="my-3 mx-1">
                <label
                  htmlFor="confirmPassword"
                  className="text-[#BFBFBF] text-sm block"
                >
                  Confirm Password*
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword.value}
                  onChange={(e) =>
                    setConfirmPassword({
                      ...confirmPassword,
                      value: e.target.value,
                    })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    confirmPassword.error
                      ? "border-red-500"
                      : "border-[#BFBFBF]"
                  } rounded-md px-4 py-1 w-full`}
                />
              </div>
            </div>
            {/** Form Section */}

            <div className="mt-5 text-center text-sm">
              <div>
                <input type="checkBox" className="shadow-sm border-0" />
                <span className="text-[#BFBFBF] text-sm mx-3">
                  I agree with the terms of use
                </span>
              </div>

              <div className="my-3 text-center">
                <button
                  onClick={() => handleSignUp()}
                  className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default AdminRegisterComp;
