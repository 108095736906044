import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import axios from "axios";

import Card from "../../shared/card";
import prevArrow from "../../assets/icons/prev-arrow.png";

//crypto images
import btcAddress from "../../assets/images/btcbarcode.jpeg";
import ethAddress from "../../assets/images/ethbarcode.jpg";
import usdAddress from "../../assets/images/usdtbarcode.jpg";
import { Deposit } from "../../common";
import { RETRIEVE_USERS_DEPOSITS } from "../../services/deposits";
import StatusBadge from "../../shared/status-badge";
import { getItem } from "../../utils";

type Crypto = {
  id: number;
  name: string;
  image: string;
  address: string;
};

const DepositComp = () => {
  const url: any = process.env.REACT_APP_BASE_URL;
  const [deposits, setDeposits] = useState<Deposit[]>([]);

  //form input states
  const [usersPlan, setUsersPlan] = useState<any>(null);
  const [currencyName, setCurrencyName] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });
  const [packages, setPackage] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [amount, setAmount] = useState<{ value: number; error: boolean }>({
    value: 1,
    error: false,
  });

  // local States
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [currentCrypto, setCurrentCrypto] = useState<Crypto | null>(null);
  const [switchTab, setSwitchTab] = useState<boolean>(false);

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (currencyName.value === "" || undefined || null) {
      isValid = false;
      setCurrencyName({ ...currencyName, error: true });
    } else {
      setCurrencyName({ ...currencyName, error: false });
    }
    if (amount.value === undefined || null) {
      isValid = false;
      setAmount({ ...amount, error: true });
    } else {
      setAmount({ ...amount, error: false });
    }
    if (packages.value === undefined || null) {
      isValid = false;
      setPackage({ ...packages, error: true });
    } else {
      setPackage({ ...packages, error: false });
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (inputCheck()) {
      setLoading(true);
      const data = {
        amount: amount.value,
        currencyName: currencyName.value,
        package: packages.value,
      };
      axios
        .post(`${url}/create-deposit`, data)
        .then((res) => {
          setLoading(false);
          const { message, data } = res.data;
          // console.log("data", data);
          notify("success", message);
          retrieveDeposits();
          window.location.href = `/confirm-deposit/${data._id}`;
        })
        .catch((err) => {
          setLoading(false);
          const { error } = err.response.data;
          notify("error", error);
        });
    }
  };

  const cryptos: Crypto[] = [
    {
      id: 1,
      name: "Bitcoin",
      image: btcAddress,
      address: "bc1qnmqjgqpguulwup65d2jf4v4mff8qadl8fppryf",
    },
    {
      id: 2,
      name: "Ethereum",
      image: ethAddress,
      address: "0x32dC6BcE9e203096b72Da4eeAd16Adc9b3d03DFE",
    },
    {
      id: 3,
      name: "Usdt",
      image: usdAddress,
      address: "0x32dC6BcE9e203096b72Da4eeAd16Adc9b3d03DFE",
    },
  ];

  const selectCrypto = (name: string) => {
    const crypto: Crypto | undefined = cryptos.find(
      (item) => item.name === name
    );
    if (crypto) {
      setCurrentCrypto(crypto);
    }
  };

  const toggleTab = (tab: string) => {
    if (tab === "crypto") {
      setSwitchTab(false);
    } else {
      setSwitchTab(true);
    }
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveDeposits = () => {
    setLoading(true);
    RETRIEVE_USERS_DEPOSITS()
      .then((res) => {
        const { message, data } = res.data;
        notify("success", message);
        setDeposits(data);
        setLoading(false);
      })
      .catch((err) => {
        const { error } = err.response.data;
        setLoading(false);
        notify("error", error);
      });
  };

  const copyWalletAddress = (address: string): void => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        notify("success", "Address copied");
      })
      .catch((err) => notify("error", err));
  };

  useEffect(() => {
    retrieveDeposits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const plan = getItem("plan");
    if (plan) {
      setUsersPlan(plan);
      setAmount({ ...packages, value: plan.minAmount });
      setPackage({ ...packages, value: plan.title.toLowerCase() });
      // console.log(packages.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto grid grid-cols-1 mt-4">
        <div className="mb-6">
          <div className="text-center mb-2">
            <h3 className="text-[#0F2137] text-2xl font-medium">
              Deposit Crypto
            </h3>
          </div>

          <div className="flex justify-between">
            <div>
              <h3
                onClick={() => toggleTab("crypto")}
                className={`${
                  !switchTab
                    ? "text-[#0F2137] border-b-4 border-[#FF971D]"
                    : "text-gray-400"
                } pb-2 
                            text-lg sm:text-xl md:text-xl lg:text-xl
                            font-medium hover:cursor-pointer`}
              >
                Crypto
              </h3>
            </div>
            <div>
              <h3
                onClick={() => toggleTab("bank Transfer")}
                className={`${
                  switchTab
                    ? "text-[#0F2137] border-b-4 border-[#FF971D]"
                    : "text-gray-400"
                } pb-2 
                            text-lg sm:text-xl md:text-xl lg:text-xl 
                            font-medium hover:cursor-pointer`}
              >
                Bank Transfer
              </h3>
            </div>
          </div>
        </div>

        {!switchTab ? (
          <Card type="lg">
            <form
              className="mx-1 sm:mx-2 md:mx-3 lg:mx-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <select
                  name="currency"
                  id="currency"
                  onChange={(e: any) => {
                    selectCrypto(e.target.value);
                    setCurrencyName({ ...currencyName, value: e.target.value });
                  }}
                  className="w-full rounded-3xl py-2 px-4 text-gray-400"
                  required
                >
                  <option value="">Currency</option>
                  {cryptos.length > 0 &&
                    cryptos.map((item: Crypto, key: number) => {
                      return (
                        <option key={key} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <input
                  type="number"
                  placeholder="Amount in $"
                  min={usersPlan ? usersPlan.minAmount : 1}
                  value={amount.value}
                  onChange={(e: any) =>
                    setAmount({ ...amount, value: parseInt(e.target.value) })
                  }
                  className="w-full rounded-3xl py-2 px-4 text-gray-400 "
                />
              </div>

              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <select
                  name="currency"
                  id="currency"
                  value={packages.value}
                  onChange={(e: any) => {
                    setPackage({ ...packages, value: e.target.value });
                  }}
                  className="w-full rounded-3xl py-2 px-4 text-gray-400"
                >
                  <option value="">Select Package</option>
                  <option value="regular">Regular</option>
                  <option value="bronze">Bronze</option>
                  <option value="gold">Gold</option>
                </select>
              </div>

              {currentCrypto && (
                <div className="my-4">
                  <div className="flex justify-center mb-4">
                    <img
                      src={currentCrypto?.image}
                      width="150px"
                      height="150px"
                      alt="barcode"
                    />
                  </div>
                  <div
                    className="flex justify-end
                                        w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]
                                    "
                  >
                    <input
                      type="text"
                      className="w-full pl-4 border-0 rounded-3xl text-gray-400"
                      value={currentCrypto?.address}
                      onChange={(e) =>
                        setAddress({ ...address, value: e.target.value })
                      }
                    />
                    <div
                      className="bg-[#494949] text-white p-3
                                            rounded-l-none rounded-r-3xl rounded-b-3xl hover:cursor-pointer
                                            "
                      onClick={() => copyWalletAddress(currentCrypto.address)}
                    >
                      <img
                        src={prevArrow}
                        width="20px"
                        height="20px"
                        alt="copy"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="my-4">
                <button className="py-3 text-center w-full rounded-3xl bg-[#FF971D] text-white">
                  {loading ? "Loading..." : "Make Deposit"}
                </button>
              </div>
            </form>
          </Card>
        ) : (
          <Card type="lg">
            <div className="text-center my-4">
              <h4 className="text-[#0F2137] text-xl font-medium">
                Please contact the admin to use this feature
              </h4>
            </div>
          </Card>
        )}

        <div className="my-8">
          <Card type="lg">
            <div className="overflow-scroll">
              <table className="table table-auto w-full mx-auto border-spacing-y-4">
                <thead>
                  <tr className="border-spacing-y-4">
                    <th className="table-caption text-left">Email</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {deposits.length > 0 ? (
                    deposits.map((item: Deposit, key: number) => {
                      return (
                        <tr key={key} className="my-4">
                          <td className="text-left border-spacing-y-4">
                            {item.firstname}
                          </td>
                          <td className="text-center py-3">{item.amount}</td>
                          <td className="text-center py-3">
                            {moment(item?.createdAt).format("MM-DD-YYYY")}
                          </td>
                          <td className="text-center py-3">
                            <StatusBadge status={item.status} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center py-3">
                        No deposits available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default DepositComp;
