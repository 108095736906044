import React, { useState, useEffect } from 'react';
import Layout from '../../../shared/layout';
import WithdrawComp from '../../../components/users-dashboard/withdraw-comp';
import AdminWithdraw from '../../../components/admin-dashboard/withdraw';
import { getItem } from '../../../utils';

const WithDraw = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        const { isLoggedIn, user } = getItem('auth');
        (isLoggedIn && (user.role === 'user')) ? setIsAdmin(false) : setIsAdmin(true); 
    }, []);
    
    return (
        <Layout>
            { isAdmin ? <AdminWithdraw /> : <WithdrawComp /> }
        </Layout>
    )
}

export default WithDraw;