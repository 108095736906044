import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import { Transaction } from "../../common";
import Card from "../../shared/card";
import { RETRIEVE_SINGLE_TRANSACTIONS } from "../../services";
import { UPDATE_PARTIAL_DEPOSIT } from "../../services/deposits";

function TransactionDetailComp() {
  const params = useParams();
  const { id } = params;
  const [transaction, setTransaction] = useState<Transaction>();
  const [loading, setLoading] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);
  const [partialAmount, setPartialAmount] = useState<string>("");

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveTransactionDetail = (id: string) => {
    RETRIEVE_SINGLE_TRANSACTIONS(id)
      .then((res) => {
        const { message, data } = res.data;
        notify("success", message);
        setTransaction(data);
      })
      .catch((err) => {
        const { error } = err.response.data;
        setLoading(false);
        notify("error", error);
      });
  };

  useEffect(() => {
    if (id) {
      retrieveTransactionDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const approvePartial = async () => {
    setApproving(true);
    UPDATE_PARTIAL_DEPOSIT({ id, partialAmount: Number(partialAmount) })
      .then((res) => {
        setApproving(false);
        const { message } = res.data;
        notify("success", message);
      })
      .catch((err) => {
        setApproving(false);
        const { error } = err.response.data;
        notify("error", error);
      });
  };

  return (
    <>
      {loading ? (
        <div className="mx-auto w-11/12 sm:w-9/12 md:w-8/12 lg:w-8/12">
          <Card type="lg">
            <h3>Loading</h3>
          </Card>
        </div>
      ) : (
        <div className="mx-auto w-11/12 sm:w-9/12 md:w-8/12 lg:w-8/12">
          {transaction ? (
            <div className="my-8">
              <Card
                type="lg"
                title={`${
                  transaction?.transactionType || "Transaction"
                } Details`}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                  <div className="my-3 px-4">
                    <p>First Name</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.firstname}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Last Name</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.lastname}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Type</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.transactionType}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Currency</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.currency}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Wallet Address</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.walletAddress}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Package</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.package}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Amount</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      ${transaction?.amount}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Transaction Status</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {transaction?.status}
                    </h3>
                  </div>
                  <div className="my-3 px-4">
                    <p>Date</p>
                    <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                      {moment(transaction?.createdAt).format("MM-DD-YYYY")}
                    </h3>
                  </div>
                </div>
                <span>
                  Enter a value to approve only that amount from this deposit
                </span>
                <div className="flex">
                  <input
                    type="number"
                    name="partialAmount"
                    value={partialAmount}
                    onChange={(e) => setPartialAmount(e.target.value)}
                    className={`bg-white text-[#6A6A6A] border-2 
                   "border-[#BFBFBF]"
                   rounded-md px-4 py-2 w-9/12`}
                  />
                  {approving === false ? (
                    <button
                      className="bg-[#FF971D] text-white py-2 px-4 rounded-md w-3/12"
                      onClick={approvePartial}
                    >
                      Approve
                    </button>
                  ) : (
                    <button className="bg-[#FF971D] text-white py-2 px-4 rounded-md w-3/12">
                      Loading...
                    </button>
                  )}
                </div>
                <div className="my-3 w-full flex justify-center">
                  {transaction.confirmationImage && (
                    <a
                      href={transaction.confirmationImage}
                      target="_blank"
                      rel="noreferrer"
                      className="py-3 px-3 lg:px-6 text-center mx-auto w-full rounded-3xl hover:cursor-pointer bg-[#FF971D] text-white"
                    >
                      View transaction proof
                    </a>
                  )}
                </div>
              </Card>
            </div>
          ) : (
            <div className="my-8">
              <Card type="lg">
                <div>
                  <h3>No transaction Details found</h3>
                </div>
              </Card>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default TransactionDetailComp;
