import React from 'react';

// images
import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';
import femaleProfile from '../../assets/images/femaleProfile.jpeg';
import maleProfile1 from '../../assets/images/maleProfile1.jpeg';
import maleProfile2 from '../../assets/images/maleProfile2.jpeg';
// import maleProfile3 from '../../assets/images/maleProfile3.png';
import ClientCard from './client-card';

type ClientFeedback = {
    title: string;
    content: string;
    user: { name: string, profileImg: string, handle: string }
}

function Clients() {

    const clientsFeedBacks: ClientFeedback[] = [
        {
            title: "Support System",
            content: "Customer care support system is responsive and helpful. Best of all is being able to help many other users make their first  interest from Smarthills",
            user: {
                name: "Steve Jones",
                handle: "@steve.jones",
                profileImg: maleProfile1
            }
        },
        {
            title: "User Experience",
            content: "Smart-hills is so awesome! Look is amazing. User interface is easy to understand,the payment system is fast and reliable. Everything is just as I was told it would be.",
            user: {
                name: "Sarah Smith",
                handle: "@sarahsmitch",
                profileImg: femaleProfile
            }
        },
        {
            title: "Layout Structure",
            content: "I just seem to love how Smart-hills is able to detail out everything un an explanatory format, even a newbie or layman would be able to catch up in seconds",
            user: {
                name: "Kamala Brou",
                handle: "@kamala",
                profileImg: maleProfile2
            }
        },
        // {
        //     title: "Modern look & trending design",
        //     content: "Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.",
        //     user: {
        //         name: "Denny Hilguston",
        //         handle: "@denny.hil",
        //         profileImg: maleProfile3
        //     }
        // }
    ]

  return (
    <>
        <div className='w-full mb-16'>
            <div className="mx-auto w-11/12 sm:w-10/12 md:9/12 lg:w-8/12" >
                {/* top section */}
                <div className="text-center mb-6 mt-12">
                    {/* <p className='text-[#244886] font-normal my-2'>Testimonial</p> */}
                    <h3 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold text-[#0F2137]'>
                        Meet Client Satisfaction
                    </h3>
                </div>
                {/* top section */}

                <div className="grid grid-cols-1 space-x-3 space-y-2 my-4
                    sm:grid-cols-2
                    md:grid-cols-3 
                    lg:grid-cols-3"
                >
                    {
                        clientsFeedBacks && clientsFeedBacks.map((item: ClientFeedback, key: number) => {
                            return <ClientCard key={key} title={item.title} content={item.content} user={item.user} />
                        })
                    }
                </div>

                <div className="my-6 flex justify-center">
                    <img src={arrowLeft} alt="arrowLeft" className='inline mx-1 cursor-pointer' />
                    <img src={arrowRight} alt="arrowRight" className='inline mx-1 cursor-pointer' />
                </div>
            </div>
        </div>
    </>
  )
}

export default Clients