import React from "react";
import { Link } from "react-router-dom";

// logo
import logo from "../../assets/images/logo-yellow.png";

const RefundPolicyComp = () => {
  return (
    <>
      <div className="w-full">
        <div className="relative top-0 bg-[#FF971D] text-white">
          <h1 className="ml-16 mr-auto w-10/12 py-16 text-2xl lg:text-4xl font-bold">
            Refund policy
          </h1>
          <div className="absolute right-4 -bottom-16 rounded-full bg-white px-6 py-10 w-max h-max shadow-lg">
            <Link to="/">
              <img src={logo} width="80px" height="80px" alt="logo" />
            </Link>
            {/* <h2>Logo</h2> */}
          </div>
        </div>
        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <br />
            {/* <h3 className="text-lg lg:text-2xl font-bold">Introduction:</h3> */}
            <p className="text-lg font-normal mt-8 mb-4">
              Smarthills (hereinafter “we”, or “Smarthills”) private limited
              company incorporated under the law, has created this Refund Policy
              in order to prevent disagreements and misunderstandings our Users
              ( also referred as “you”, or “user”) and informs that all
              confirmed exchanges, buys, sells, deposits and withdrawals of fiat
              and virtual currency via{" "}
              <a href="www.smarthills.org">www.smarthills.org</a> (hereinafter
              “Website”)or any other Smarthills Products are final, and we do
              not accept any returns or provide refunds for Users, except as
              otherwise provided in Refund Policy.
            </p>
            <p className="text-lg font-normal my-4">
              By using and/or otherwise accessing the Services or any other
              Products provided by Smarthills on the following website: you
              confirm that you have read, understood, accept and agree to follow
              Refund Policy.
            </p>
          </div>
        </div>

        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <h3 className="text-lg lg:text-2xl font-bold">1. GENERAL TERMS </h3>
            <p className="text-lg font-normal my-4">
              1.1. This Refund Policy concerns exclusively a User’s deposited
              funds, and it does not refer to any refunds for send, sale or
              exchange transactions that were processed as Smarthills services.
              Refunds in excess of the original transaction amount are
              prohibited for any payment method.{" "}
            </p>
            <p className="text-lg font-normal my-4">
              1.2. The User acknowledges the finality of all purchases, sales or
              exchanges of cryptocurrency, whether they are completed or
              pending. Virtual currencies are digital assets, transactions
              (either completed or pending) of which are irreversible,
              non-refundable and cannot be changed once submitted by nature of
              the cryptocurrency and blockchain technology itself.{" "}
            </p>
            <p className="text-lg font-normal my-4">
              1.3. In accordance with this Refund Policy, a refund to the User
              is allowed only when funds were not credited to the User’s
              cryptocurrency wallet after completion of a purchase transaction
              processing due to technical reasons or as a result of an error. In
              such cases, if the refund request is deemed to be justified and
              approved by Smarthills, the funds will be refunded back to the
              User through the same payment method and to the same digital
              wallet, credit/debit card or bank account from which the funds
              were originally received.{" "}
            </p>
            <p className="text-lg font-normal my-4">
              1.4. SmartHills neither bears any responsibility, provides any
              refunds, nor covers any losses caused by inappropriate or
              unauthorised use of the Services or any other Products.
            </p>
          </div>
        </div>

        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <h3 className="text-lg lg:text-2xl font-bold">2. REFUND REQUEST</h3>
            <p className="text-lg font-normal my-8">
              2.1. In order to apply for a refund where a technical error has
              been identified, the User must complete the respective refund
              form. You can obtain the Refund Form under request sent to
              support@smarthills.org .
            </p>

            <p className="text-lg font-normal my-8">
              2.2. If a User does not provide the necessary details with the
              request, including the description and justification for the
              refund, or if the User has already used all or part of the
              purchased cryptocurrency assets, Smarthills has the right to
              decline such refund request without further consideration.
            </p>
            <p className="text-lg font-normal my-8">
              2.3. Upon refund request, the investor is entitled to pay the
              company (Smarthills) 10% of total amount requested to be refunded.
              Refunds will be processed when payment is made
            </p>
            <p className="text-lg font-normal my-8">
              2.4. To comply with the applicable Anti-Money Laundering (AML) and
              Counter Terrorist Financing (CTF) rules and regulations or any
              other regulations, and to prevent prohibited conduct, all payments
              and information related to the refund may be verified by
              Smarthills. In such a case, Smarthills,, at its sole discretion,
              may request from you to provide certain documents and information,
              including but not limited to identification documents, copy of
              your credit card or bank details and/or any other proof required
              to affect the refund. In case you fail to provide appropriate
              documents or information, or in case of any doubts as to the
              authenticity of provided documents and information, Smarthills
              shall be entitled to cease the refund procedure until you take
              appropriate measures, as requested.
            </p>
            <p className="text-lg font-normal my-8">
              2.5. The User acknowledges and agrees, that the submission of a
              refund request does not guarantee that the User’s request will be
              approved. Moreover, we reserve the right to cancel your order
              without any refund, or decline any refund request, if it suspects
              that you have or are engaged in, or have in any way been involved
              in, fraudulent or illegal activity.
            </p>
          </div>
        </div>

        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <h3 className="text-lg lg:text-2xl font-bold">
              3. PROCESSING TIME
            </h3>
            <p className="text-lg font-normal my-8">
              3.1. Smart shall notify the User of its final decision regarding
              refund within seven (7) business days following the receipt of all
              requested documents or information, or within fourteen (14)
              business days following the receipt and reviewing of refund
              request if no further information or document is required.
            </p>

            <p className="text-lg font-normal my-8">
              3.2. Refunds will be processed without undue delay, and in any
              event within seven (7) days after Smarthills approved a refund.
              Refund transactions can take up to ten (10) business days,
              excluding time needed for funds transfer from the bank or payment
              provider to your account.
            </p>
          </div>
        </div>

        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <h3 className="text-lg lg:text-2xl font-bold">
              4. CHANGEBACK NOTICE
            </h3>
            <p className="text-lg font-normal my-8">
              4.1. Smarthills expects the User to contact via email:
              support@smarthills.org to resolve any problem or issue related to
              his/her payments before the User makes any Chargeback request.
              This Section does not affect any rights and/or claims, which the
              User may have against the bank/financial institution.
            </p>

            <p className="text-lg font-normal my-8">
              4.2. Smarthills reserves the right to suspend User’s account and
              lock User’s Funds during the chargeback investigation procedure.
            </p>
          </div>
        </div>

        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <h3 className="text-lg lg:text-2xl font-bold">5. MISCELLANEOUS</h3>
            <p className="text-lg font-normal my-8">
              5.1. Any charges, which arise upon processing in accordance with
              this Refund Policy, shall be borne solely by the User. Such
              charges will be deducted from the final amount of Refund.
            </p>

            <p className="text-lg font-normal my-8">
              5.2. This Refund Policy will be amended from time to time if there
              is any change in the legislation. Terms and conditions of the
              Refund Policy are subject to change by Smarthills and in the case
              of any amendments, Smarthills will make an appropriate
              announcement and inform when changes will take effect.
            </p>

            <p className="text-lg font-normal my-8">
              5.3. In case if the User does not wish to accept the revised
              Refund Policy, he/she should not continue to use Smarthills
              Website, Services or any other Products. If the User continues to
              use the Services after the date on which the change comes into
              effect, his/her use of the Services to be bound by the new Refund
              Policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicyComp;
