import { RouteType } from "./auth-routes";

// pages
import { Dashboard, PricingPlan, Profile } from "../pages/admin";
import NotFoundPage from "../pages/Not-found";
import Deposit from "../pages/admin/deposit";
import Privacy from "../pages/privacy";
import WithDraw from "../pages/admin/withdraw";
import UsersPage from "../pages/admin/users";
import UserDetailPage from "../pages/admin/user-detail";
import TransactionDetailPage from "../pages/admin/transaction-detail";
import ConfirmDeposit from "../pages/admin/confirm-dashboard";
import Referrals from "../pages/admin/referrals";

const privateRoutes: RouteType[] = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/pricing-plan",
    component: <PricingPlan />,
  },
  {
    path: "/deposit",
    component: <Deposit />,
  },
  {
    path: "/confirm-deposit/:id",
    component: <ConfirmDeposit />,
  },
  {
    path: "/referrals",
    component: <Referrals />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/privacy",
    component: <Privacy />,
  },
  {
    path: "/withdraw",
    component: <WithDraw />,
  },
  {
    path: "/users",
    component: <UsersPage />,
  },
  {
    path: "/users/:id",
    component: <UserDetailPage />,
  },
  {
    path: "/transaction/:id",
    component: <TransactionDetailPage />,
  },
  {
    path: "*",
    component: <NotFoundPage />,
  },
];

export default privateRoutes;
