import axios from "./config";
// axios.defaults.withCredentials = true;

const url = process.env.REACT_APP_BASE_URL;

export const RETRIEVE_USERS = async () => {
  return axios.get(`${url}/admine/users`);
};

export const RETRIEVE_USER_BY_ID = async (id: string) => {
  return axios.get(`${url}/admine/users/${id}`);
};

export const FUND_USER = async (data: { id: string; amount: number }) => {
  const payload = { amount: data.amount };

  return axios.post(`${url}/admine/users/fund/${data.id}`, payload);
};

export const DEPOSIT_USER_WALLET = async (data: {
  id: string;
  amount: number;
}) => {
  console.log(data);
  const payload = { amount: data.amount };
  // console.log(data);
  return axios.post(`${url}/admine/users/deposit/${data.id}`, payload);
};

export const UPGRADE_USER = async (data: { id: string; role: string }) => {
  const payload = { role: data.role };
  return axios.post(`${url}/admine/users/upgrade/${data.id}`, payload, {
    headers: {},
  });
};

export const VERIFY_USER = async (id: string) => {
  return axios.get(`${url}/admine/users/verify/${id}`);
};

export const TOGGLE_USER_WITHDRAWAL_STATUS = async (id: string) => {
  return axios.get(`${url}/admine/users/withdrawal-status/${id}`);
};

export const RETRIEVE_USER_PROFILE = async () => {
  return axios.get(`${url}/profile`);
};
export const RETRIEVE_USER_REFERRALS = async () => {
  return axios.get(`${url}/profile/referrals`);
};

export const USER_PROFILE_VERIFY = async (data: FormData) => {
  return axios.post(`${url}/profile/verify`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
