import React from "react";

type Props = {
  title: string;
  content: string;
  user: { name: string; profileImg: string; handle: string };
};

const ClientCard = ({ title, content, user }: Props) => {
  return (
    <>
      <div
        className="bg-white hover:shadow-xl rounded-sm py-8 px-4"
        style={{ border: "1px solid", borderColor: "#fcfcfc" }}
      >
        <h3 className="text-[#343D48] text-lg font-semibold my-3">{title}</h3>
        <p className="text-[#343D48] text-sm font-light mb-4">{content}</p>

        {/* user-profile */}
        <div className="flex justify-start">
          <div className="mx-3 rounded-full w-12 h-12">
            <img src={user.profileImg} alt="profile" className="rounded-full" width="48px" height="48px" />
          </div>
          <div>
            <p className="text-[#343D48] text-lg font-semibold">{user.name}</p>
            <p className="text-[#25A0FF] text-sm font-light">{user.handle}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientCard;
