import React from 'react';
import { Link } from 'react-router-dom';

import './home.css';
import phoneImg from '../../assets/images/phone-hand.png';

function Payment() {
  return (
    <>
        <div className='curved-bg w-full sm:w-full md:w-11/12 lg:w-10/12 ml-auto mr-0 font-[Poppins]'>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <div className='pt-8'>
                    <img src={phoneImg}  alt="phone" />
                </div>

                <div className='mx-auto w-full sm:w-5/6 md:w-5/6 lg:w-4/6 px-10 mt-32'>
                    {/* <p className='text-[#244886] text-sm my-2'>CORE FEATURES</p> */}
                    <h3 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold text-[#0F2137]'>Fast and <br /> Secure Payment <br /> Transaction System</h3>
                    <p className='text-[#02073E] my-8 w-5/6 text-sm'>
                        We at Smart-hills are dedicated to ensuring that our customers get the best quality services provided on our platform, at the speed of light and without complications. Rest-assured, Smart-hills has always got you covered.
                    </p>

                    <button className='bg-[#FF971D] text-white rounded-3xl text-thin px-6 py-1'>
                        <Link to="/about-us">Learn More</Link>
                    </button>
                </div>
            </div>
            {/* <div className="mx-auto w-8/12">
            </div> */}
        </div>
    </>
  )
}

export default Payment