import React from 'react';
import ContactUsComp from '../../components/contact-us-comp';

const ContactUs = () => {
    return(
        <>
            <ContactUsComp />
        </>
    )
}

export default ContactUs;