import React from 'react';
import { Link } from 'react-router-dom';

import './home.css';
import TopBar from './top-bar';


const HeroComp = () => {
  return (
    <>
        <div className="hero-wrapper w-full">
            <div className="mx-auto w-11/12 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-8/12">
                <TopBar />
            </div>
            <div className="relative mx-auto w-10/12 sm:w-10/12 md:w-9/12 lg:w-8/12 py-8">
                <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2'>
                    <div >
                        <h1 className="text-white text-4xl font-bold mb-4">
                            Welcome to Smarthills
                        </h1>
                        <p className='text-white text-[1rem] mt-4 mb-8 font-thin' style={{lineHeight: '1.8rem'}}>
                            Where we provide you with assurance, security and income like you have never experienced before.
                        </p>

                        <div className='text-center sm:text-center md:text-center lg:text-left'>
                            <button className='bg-white text-black mt-4 py-2 px-6 rounded-3xl'>
                                <Link to="/sign-in">Get Started</Link>
                            </button>
                        </div>
                    </div>

                    <div className='hidden sm:hidden md:block lg:block'>
                        <div className="userImage"></div>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

export default HeroComp;