import React from 'react'
import AdminLoginComp from '../../../components/admin-dashboard/login';

const AdminLogin = () => {
  return (
    <>
        <AdminLoginComp />
    </>
  )
}

export default AdminLogin;