import React from 'react';

//components
import Layout from '../../../shared/layout'
import UserDetailComp from '../../../components/admin-dashboard/user-detail-comp';

const UserDetailPage = () => {
  return (
    <>
        <Layout>
            <UserDetailComp />
        </Layout>
    </>
  )
}

export default UserDetailPage;