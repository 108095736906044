import React from 'react';
import DisclaimerComp from '../../components/disclaimer-comp';

const Disclaimer = () => {
    return(
        <>
            <DisclaimerComp />
        </>
    )
}

export default Disclaimer;