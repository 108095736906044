import React from 'react';
import TermsAndConditionComp from '../../components/terms-and-condition-comp';

const TermsAndCondition = () => {
    return(
        <>
            <TermsAndConditionComp />
        </>
    )
}

export default TermsAndCondition;