import React from 'react'
import SignInComp from '../../components/signin-comp';

const SignIn = () => {
  return (
    <>
        <SignInComp />
    </>
  )
}

export default SignIn;