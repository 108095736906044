import React, { useState, useEffect } from 'react';

// components
import Layout from '../../../shared/layout';
import DepositComp from '../../../components/users-dashboard/deposit-comp';
import AdminDeposit from '../../../components/admin-dashboard/deposit';
import { getItem } from '../../../utils';



const Deposit = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        const { isLoggedIn, user } = getItem('auth');
        (isLoggedIn && (user.role === 'user')) ? setIsAdmin(false) : setIsAdmin(true); 
    }, []);

    return (
        <Layout>
            { isAdmin ? <AdminDeposit /> : <DepositComp /> }
            {/* <DepositComp /> */}
            {/* <AdminDeposit /> */}
        </Layout>
    )
}

export default Deposit;