import React from 'react'
import SignUpComp from '../../components/signup-comp';

const SignUp = () => {
  return (
    <>
        <SignUpComp />
    </>
  )
}

export default SignUp;