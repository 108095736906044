import React from 'react';
import { Link } from 'react-router-dom';

// logo
import logo from '../../assets/images/logo-yellow.png';

const AboutUsComp = () => {
  return (
    <>
        <div className='w-full'>
            <div className="relative top-0 bg-[#FF971D] text-white">
                <h1 className='ml-16 mr-auto w-10/12 py-16 text-2xl lg:text-4xl font-bold'>ABOUT US</h1>
                <div className="absolute right-4 -bottom-16 rounded-full bg-white px-6 py-10 w-max h-max shadow-lg">
                    <Link to="/">
                        <img src={logo} width="80px" height="80px" alt="logo" />
                    </Link>
                    {/* <h2>Logo</h2> */}
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Who are SmartHills?</h3>
                    <p className='text-lg font-normal my-8'>
                        We are a global investment community, where Investments have been made easy and simple. Here, we provide you with the most basic tools and facilities at your disposal. 
                        We are highly invested in providing the community with the best life has to offer.
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">What does SmartHills bring to the table?</h3>
                    <ol className='text-lg font-normal my-8'>
                        <li className='my-4'>
                            <span className='text-sm lg:text-lg font-semibold'>A secure and safe to use system.</span>
                            <br />
                            Our platform is built on the best security system networks with absolute care to ensure that your personal details are left personal.
                        </li>
                        <li className='my-4'>
                            <span className='text-sm lg:text-lg font-semibold'>Fast and secure transactions</span>
                            <br />
                            Transacting on a platform can be a stressful thing, especially with the instability in network access. 
                            We provide a secure system that ensures you don't lose money on the go, no matter what.
                        </li>
                        <li className='my-4'>
                            <span className='text-sm lg:text-lg font-semibold'>Packages.</span>
                            <br />
                            Affordable packages that meet your pocket, so you don't have to be bothered about if you have enough funds to invest.
                        </li>
                        <li className='my-4'>
                            <span className='text-sm lg:text-lg font-semibold'>Customer Care service.</span>
                            <br />
                            We have a team on standby, always ready to take your questions and requests. Making sure our customers remain happy at all times.
                        </li>
                        
                    </ol>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Our Aim and Vision</h3>
                    <p className='text-lg font-normal my-8'>
                        We wish to bring to the community unlimited access to financial wealth and personal growth,
                        especially as the economy can be really inhospitable and draining.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutUsComp