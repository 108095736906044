import React from 'react';
import { Link } from 'react-router-dom';

// logo
import logo from '../../assets/images/logo-yellow.png';

const PrivacyComp = () => {
  return (
    <>
        <div className='w-full'>
            <div className="relative top-0 bg-[#FF971D] text-white">
                <h1 className='ml-16 mr-auto w-10/12 py-16 text-2xl lg:text-4xl font-bold'>Privacy and Policy</h1>
                <div className="absolute right-4 -bottom-16 rounded-full bg-white px-6 py-10 w-max h-max shadow-lg">
                    <Link to="/">
                        <img src={logo} width="80px" height="80px" alt="logo" />
                    </Link>
                    {/* <h2>Logo</h2> */}
                </div>
            </div>
            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Introduction:</h3>
                    <p className='text-lg font-normal my-8'>
                        This page informs you of our policies regarding the collection, use, and disclosure of personal data, when you use our service and the choices you have associated with that data. 
                        We at SmartHills, do not take your trusting us with your information for granted.
                        <br />
                        <br />
                        We use your data to provide and improve the service. 
                        By using the service, you agree to the collection and use of information in accordance with this policy. 
                        Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                        <br />
                        <br />
                        The privacy of our users is important to us and we are committed to safeguarding it responsibly and in accordance with the legal requirements of the countries in which we operate. 
                        Hence, this Privacy Policy explains the type of Your Personal Information which we collect, why we collect it, and what we do with it.
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Definition:</h3>
                    <p className='text-lg font-normal my-8'>
                        <strong>Account:</strong> means an account opened and maintained by you with SmartHills to enable you to use or access the services.
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Applicable Laws</strong> means all laws in force and effect as of the date hereof and which may be enacted or brought into force and effect hereinafter in the applicable Territory, including statutes, rules, regulations, directions, bye-laws, notifications, ordinances and judgments having force of law, or any final interpretation by a court of law having jurisdiction over the matter in question as may be in force and effect during the subsistence of this Privacy Policy;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Cookies:</strong> A cookie is a small data file that is transferred to Your computer or mobile device. It enables us to remember Your account log-in information, IP addresses, web traffic, number of times You visit, browser type and version, device details, date and time of visits.
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Data Protection Legislation:</strong> means the Data Protection Regulation 2019, the General Data Protection Regulation (Regulation (EU) 2016/679) ('GDPR') and all other application legislation relating to privacy or data protection in the Territory and the territory of its affiliates.
                        SmartHills means the cryptocurrency exchange service offered by the Company on the Site pursuant to which a User or a Merchant may buy and sell cryptocurrency and accept cryptocurrency payments;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Personal Information:</strong> Any information that can be used to identify a living person including Identity Data such as your full name and government issued identification number, Contact Data such as contact and email address, 
                        Financial Data such as credit or debit card information, bank account number, etc. 
                        Taxpayer identification, it may also include anonymous information that is linked to you. 
                        For example, your internet protocol (IP), log-in information, address, location, device or transaction data and/or such other data personally identifiable to You as specified in this Privacy Policy.
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Services</strong> means access to the Site, APIs or Apps provided to you by Patricia which enables you to access SmartHills either as a merchant or user, and other related services;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>User or You or Your:</strong> means user of the Site, APIs or Apps that has opened an Account with us and/or utilizes our Services.
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Special Categories of Personal Information:</strong> means details about Your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about Your health and genetic and biometric data.
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">Objective:</h3>
                    <p className='text-lg font-normal my-8'>
                        This Privacy Policy provides all persons whose Personal Information we hold with a description of the types of Personal Information we collect, 
                        the purposes for which we collect that Personal Information, the other parties with whom we may share Personal Information with, and the measures we take to protect the security of the data in relation to the Services.
                        <br />
                        <br />
                        We may collect, record and use information about you in physical and electronic form and will hold, use and otherwise process this data in accordance with the Data Protection Legislation and as set out in this Policy.
                        <br />
                        The Personal Information we collect and use may include, amongst other things:
                    </p>

                    <p className='text-lg font-normal my-8'>
                        <strong>Identity Data:</strong> Information such as, Your full name(s), Your government-issued identity number, and Your date of birth. This data is to enable us to verify Your identity in order to offer our Services to You;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Contact Data:</strong> This is data that we use to contact You, such as Your name, residential address, email address, telephone number, details of the device You use and billing details;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Identification documents:</strong> (such as Your passport or any Government-issued identity card), a photograph (if applicable), bank statement, affidavit, utility bill or other bill and any other registration information You may provide to prove You are eligible to use our Services and in compliance with regulatory requirements on Know Your Customer (KYC);
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Usage Data:</strong> When You access the Services, our servers automatically record information about how You our Services including links You have clicked on, length of visit on certain pages, unique device identifier, log-in information, location and other device details;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Financial Data:</strong> Information, such as Bank Verification Number, personal account number or bank account information, the date and the total amount of transaction, and other information provided by financial institutions or merchants when we act on their behalf;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Information from other sources:</strong> We may also receive Personal Information about You from other sources such as our affiliate, in the event you maintain an account with the Affiliate, your financial institution, to confirm the basic Personal Information about You or our identity verification partners, in line with the fulfilment of our Anti Money Laundering and Combating the Financing of Terrorism (AML/CFT) obligations;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Transactional Data:</strong> This is information containing details of the transactions You carry out when using our Services, including geographic information;
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Marketing and Communications Data:</strong> This includes both a record of Your decision to subscribe or to withdraw from receiving marketing materials from us or from our third parties;                    
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Records:</strong> Record details of Your discussions with us if we contact You and if You contact us; and
                    </p>
                    <p className='text-lg font-normal my-8'>
                        <strong>Track Information:</strong> Information about Your visit to our website, including the full Uniform Resource Locators (URL), clickstream to, through and from our website (including date and time), length of visits to certain pages, page interaction information (such as scrolling, clicks and mouse-overs) and methods used to browse away from the page.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default PrivacyComp;