import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { User } from "../../common";
import { RETRIEVE_USER_REFERRALS } from "../../services";
import Card from "../../shared/card";

const ReferralsComp = () => {
  const [referrals, setReferrals] = useState<User[]>([]);

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveUsers = () => {
    RETRIEVE_USER_REFERRALS()
      .then((res) => {
        const { message, data } = res.data;
        notify("success", message);
        setReferrals(data);
      })
      .catch((err) => {
        const { error } = err.response.data;
        notify("error", error);
      });
  };

  useEffect(() => {
    retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="my-8 grid grid-cols-1">
        <div>
          <Card type="lg">
            {/* recent transactions */}
            <div className="flex justify-between mb-6">
              <div>
                <h3 className="text-2xl font-medium">Your Referrals</h3>
              </div>

              {/* calendar */}
              <div className="group">
                <div
                  className="relative mx-1 px-1 py-2 group  mb-1 md:mb-0"
                  id="button_pm"
                >
                  {/* <span className="firstlevel hover:text-red-500 whitespace-no-wrap text-gray-600 hover:text-blue-800"
                                    >
                                        <BiEditAlt className="text-blue hover:cursor-pointer inline" />
                                    </span> */}
                </div>
              </div>
            </div>

            <div className="overflow-x-scroll p-4">
              <table className="table table-auto w-full mx-auto border-spacing-y-4">
                <thead>
                  <tr className="border-spacing-y-4">
                    <th className="table-caption text-left">Email</th>
                    <th>Name</th>
                  </tr>
                </thead>

                <tbody>
                  {referrals.length > 0 ? (
                    referrals.map((item: User, key: number) => {
                      return (
                        <tr key={key} className="my-4">
                          <td className="text-left border-spacing-y-4">
                            {item.email}
                          </td>
                          <td className="text-center py-3 capitalize">
                            {`${item.firstname} ${item.lastname}`}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center py-3">
                        No Referrals Yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ReferralsComp;
