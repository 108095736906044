import React, { useState } from 'react';
import CreatePassword from './create-password';
import ResetPassword from './reset-password';


const ForgotPasswordComp = () => {
    const [isReset, setIsReset] = useState<boolean>(false);
    return (
        <>
            {
                isReset ? <CreatePassword /> : <ResetPassword setIsReset={setIsReset} />
            }
        </>
    )
}

export default ForgotPasswordComp;