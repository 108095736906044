import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import profile from "../../../assets/images/profile.png";
import initialImg from "../../../assets/images/upload-img.png";
import ChangePassword from "../../../components/forgot-password/change-password";
import { RETRIEVE_USER_PROFILE, USER_PROFILE_VERIFY } from "../../../services";
import Card from "../../../shared/card";
import Layout from "../../../shared/layout";

const Profile = () => {
  const [idCard, setIdCard] = useState<any>();
  const [uploadImg, setUploadImg] = useState<any>(initialImg);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [firstname, setFirstname] = useState<{ value: string; error: boolean }>(
    { value: "", error: false }
  );
  const [lastname, setLastname] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [phone, setPhone] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [profileId, setProfileId] = useState<{ value: string; error: boolean }>(
    { value: "", error: false }
  );

  const [userProfile, setUserProfile] = useState<any>();

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveProfile = () => {
    RETRIEVE_USER_PROFILE()
      .then((res) => {
        // console.log("profile", res);
        const { message, data } = res.data;
        notify("success", message);
        setUserProfile(data);
        console.log(data);
      })
      .catch((err) => {
        notify("error", err.message);
      });
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const openFile = () => {
    return fileRef.current?.click();
  };

  const setInputFields = () => {
    setFirstname(userProfile?.firstname);
    setLastname(userProfile?.lastname);
    setEmail(userProfile?.email);
    setPhone(userProfile?.phone);
  };

  const onImageChange = (e: any) => {
    setIdCard(e.target.files[0]);
    // const file = e.target.files[0];
    const reader = new FileReader();
    // const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUploadImg(reader.result);
    };
  };

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (email.value === "" || undefined || null) {
      isValid = false;
      setEmail({ ...email, error: true });
    } else {
      setEmail({ ...email, error: false });
    }
    if (firstname.value === "" || undefined || null) {
      isValid = false;
      setFirstname({ ...firstname, error: true });
    } else {
      setFirstname({ ...firstname, error: false });
    }
    return isValid;
  };

  const handleProfileVerify = () => {
    if (inputCheck()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("firstname", firstname.value);
      formData.append("email", email.value);
      formData.append("idCard", idCard);

      USER_PROFILE_VERIFY(formData)
        .then((res) => {
          setLoading(false);
          const { message } = res.data;
          notify("success", message);
          // retrieveProfile()
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };

  useEffect(() => {
    retrieveProfile();
    if (userProfile) {
      setInputFields();
      console.log(userProfile, "l");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="mx-auto w-11/12 sm:w-9/12 md:w-11/12 lg:w-9/12">
        {userProfile && (
          <div className="my-8 w-full">
            <Card type="lg">
              <div className="flex justify-center mb-4">
                <div className="bg-black rounded-full">
                  <img
                    src={profile}
                    width="50px"
                    height="50px"
                    className="rounded-full"
                    alt="barcode"
                  />
                </div>
              </div>
              <br />
              <div className="grid space-x-0 sm:space-x-1 md:space-x-2 lg:space-x-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <div className="my-4 px-4">
                  <p>First Name</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.firstname}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Last Name</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.lastname}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Email</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.email}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Phone</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.phone}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Balance</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.balance}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Earnings</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.earnings}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Role</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.role}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Email Verification Status</p>
                  <h3 className="text-sm sm:text-sm md:text-lg lg:text-lg">
                    {userProfile?.status}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Document Status Verified?</p>
                  <h3 className="text-sm sm:text-sm md:text-lg lg:text-lg">
                    {userProfile?.documentVerification === true
                      ? "True"
                      : "False"}
                  </h3>
                </div>
                {userProfile.referrer && (
                  <div className="my-4 px-4">
                    <p>Referrer</p>
                    <h3 className="text-sm sm:text-sm md:text-lg lg:text-lg">
                      {userProfile?.referrerEmail}
                    </h3>
                  </div>
                )}

                <div className="my-4 px-4">
                  <p>Referral Link</p>
                  <div className="text-sm sm:text-sm md:text-lg lg:text-lg w-full break-all">
                    {`https://smarthills.org/sign-up?ref=${userProfile?._id}`}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}

        <Card type="lg">
          <div className="m-1">
            <h3
              className={`mb-4 text-lg sm:text-xl md:text-xl lg:text-2xl font-medium`}
            >
              Verify Profile
            </h3>

            {/* form */}
            <form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                handleProfileVerify();
              }}
            >
              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <input
                  type="text"
                  placeholder="first name"
                  value={firstname?.value}
                  onChange={(e: any) =>
                    setFirstname({ ...firstname, value: e.target.value })
                  }
                  required
                  className="w-full rounded-3xl py-2 px-4 text-gray-400 "
                />
              </div>
              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <input
                  type="text"
                  placeholder="last name"
                  value={lastname?.value}
                  required
                  onChange={(e: any) =>
                    setLastname({ ...lastname, value: e.target.value })
                  }
                  className="w-full rounded-3xl py-2 px-4 text-gray-400 "
                />
              </div>
              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  value={email?.value}
                  required
                  onChange={(e: any) =>
                    setEmail({ ...email, value: e.target.value })
                  }
                  className="w-full rounded-3xl py-2 px-4 text-gray-400 "
                />
              </div>

              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <input
                  type="text"
                  placeholder="phone number"
                  required
                  value={phone?.value}
                  onChange={(e: any) =>
                    setPhone({ ...phone, value: e.target.value })
                  }
                  className="w-full rounded-3xl py-2 px-4 text-gray-400 "
                />
              </div>

              <div className="my-4 w-full border-2 border-gray-400 rounded-3xl hover:border-[#FF971D]">
                <select
                  name="currency"
                  id="currency"
                  required
                  onChange={(e: any) =>
                    setProfileId({ ...profileId, value: e.target.value })
                  }
                  className="w-full rounded-3xl py-2 px-4 text-gray-400"
                >
                  <option value="">Choose ID</option>
                  <option value="driverslicense">Driver's license</option>
                  <option value="stateId">State Id</option>
                </select>
              </div>

              <div className="my-4">
                <div className="flex justify-center mb-4">
                  <img
                    src={uploadImg}
                    width="150px"
                    onClick={() => openFile()}
                    height="150px"
                    className="hover:cursor-pointer"
                    alt="barcode"
                  />
                  <input
                    type="file"
                    ref={fileRef}
                    name="idCard"
                    multiple
                    accept="image/*"
                    onChange={(e) => onImageChange(e)}
                    className="hidden"
                    required
                  />
                </div>
              </div>

              <div className="my-6">
                <button className="py-3 text-center w-full rounded-3xl bg-[#FF971D] text-white">
                  {loading ? "Loading..." : "Verify Account"}
                </button>
              </div>
            </form>
          </div>
        </Card>

        <div className="my-8">
          <Card type="lg" title="Change Password">
            <ChangePassword />
          </Card>
        </div>
      </div>

      <ToastContainer />
    </Layout>
  );
};

export default Profile;
