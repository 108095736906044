import React from 'react';

import phoneImg from '../../assets/images/phone.png';
import bagImage from '../../assets/icons/bag.png';
import crownImage from '../../assets/icons/crown.png';
import timer from '../../assets/icons/timer.png';
import cup from '../../assets/icons/cup.png';
import cloud from '../../assets/icons/cloud.png';
import settings from '../../assets/icons/setting.png';
import star from '../../assets/icons/star.png';
import message from '../../assets/icons/message.png';


import './home.css';

const features: Array<{title: string, icon: string, content: string}> = [
    {
        title: "Exquisite Dashboard",
        icon: timer,
        content: 'The easy navigation around makes pursuit of happiness even easier'
    },
    {
        title: "Safe and Secure",
        icon: cup,
        content: "Always be sure that your investments are in safe handles, as we provide our clientele with the best security integrations and software tech has to offer"
    },
    {
        title: "Package Options",
        icon: cloud,
        content: "A variety of investment package offers are provided to meet your capital."
    },
    {
        title: "24/7 Client Support",
        icon: settings,
        content: "Customer first is our foremost priorities as we need our customers to always be satisfied at all times"
    },
    {
        title: "Financial Ease",
        icon: star,
        content: "Investment is part of the system that drives the economy, and so we are here to make sure that your investments meet your request"
    },
    {
        title: "User Friendly",
        icon: message,
        content: "We want our customers to know that our platform is hospitable and as such always be sure to expect happiness and joy everytime we involve with Smarthills."
    },
]


function AdvancedFeatures() {
  return (
    <>
        <div className='fture-bg mt-32'>
            <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:w-8/12">
                {/* top section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                    <div>
                        <img src={phoneImg} alt="phone" />
                    </div>

                    <div className='mx-auto w-full lg:w-3/4 lg:pl-20'>
                        <div className='text-center sm:text-center md:text-left lg:text-left'>
                            {/* <p className='text-[#244886] text-sm my-2'>CORE FEATURES</p> */}
                            <h3 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold text-[#0F2137]'>What we give...</h3>
                        </div>

                        <div className="flex justify-start rounded-md hover:shadow-lg hover:bg-white px-6 py-4 my-4 h-max">
                            <div className='mx-1'>
                                <img src={bagImage} width="100rem" height="100rem" alt="bag" />
                            </div>
                            <div className='mx-1'>
                                <h4 className="font-medium text-lg mb-4 text-[#0F2137]">Smart Packages</h4>
                                <p className='text-[#0F2137]'>Get amazing package deals on every investment of your choice in any of the package offers.</p>
                            </div>
                        </div>

                        <div className="flex justify-start rounded-md hover:shadow-lg hover:bg-white px-6 py-4 my-4 h-max">
                            <div className='mx-1'>
                                <img src={crownImage} width="100rem" height="100rem" alt="crown" />
                            </div>
                            <div className='mx-1'>
                                <h4 className="font-medium text-lg mb-4 text-[#0F2137]">Smart Features</h4>
                                <p className='text-[#0F2137]'>A user-friendly interface for easy navigation while on the go. Now you can never get lost while trying to make a way for yourself</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* top section */}

            </div>

            {/* bottom section */}
            <div className="my-5 mx-auto w-11/12 sm:w-10/12 md:9/12 lg:w-9/12">
                <div className="text-center mb-6">
                    {/* <p className='text-[#244886] font-normal my-2'>QUALITY FEATURES</p> */}
                    <h3 className='text-xl sm:text-xl md:text-2xl lg:text-2xl font-bold text-[#0F2137]'>
                        Experience Exciting Features On <br /> Our Platform
                    </h3>
                </div>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 space-x-2">
                    {
                        features && features.map((item: {title: string, icon: string, content: string}, key: number) => {
                            return <div key={key}>
                                <div className="flex justify-start rounded-md hover:shadow-lg hover:bg-white px-6 py-3 my-1 h-max">
                                    <div className='mx-1'>
                                        <img src={ item.icon } width="100px" height="100px" alt="crown" />
                                    </div>
                                    <div className='mx-1'>
                                        <h4 className="font-medium text-md mb-4 text-[#0F2137]">{ item?.title }</h4>
                                        <p className='text-[#0F2137] text-sm'>{ item?.content }</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default AdvancedFeatures;