import React from "react";
import { Navigate } from "react-router-dom";
import { getItem } from "../utils";

type Props = {
  children: any;
};

const ProtectedRoute = ({ children }: Props) => {
  const auth = getItem("auth");
  const token = getItem("clientToken");
  // console.log(token);
  if ((token !== null || undefined) && auth && auth.isLoggedIn) {
    return children;
  }
  return <Navigate to={"/"} replace />;
};

export const NotProtectedRoute = ({ children }: Props) => {
  // const auth = getItem('auth');
  // if(auth && auth.isLoggedIn){
  //     return <Navigate to={'/dashboard'} replace />;
  // }
  return children;
};

export default ProtectedRoute;
