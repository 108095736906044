import React from 'react';
import AboutUsComp from '../../components/about-us-comp';

const AboutUs = () => {
    return(
        <>
            <AboutUsComp />
        </>
    )
}

export default AboutUs;