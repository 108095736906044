import { ReactNode } from "react";

//pages
import { HomePage, SignUp, SignIn } from "../pages";
import AboutUs from "../pages/about-us";
import AdminLogin from "../pages/admin/login";
import TermsAndCondition from "../pages/terms-and-condition";
import VerifyEmail from "../pages/verify-email";

import NotFoundPage from "../pages/Not-found";
import Privacy from "../pages/privacy";
import AdminRgister from "../pages/admin/register";
import ForgotPassword from "../pages/forgot-password";
import ContactUs from "../pages/contact-us";
import RefundPolicy from "../pages/refund-policy";
import Disclaimer from "../pages/disclaimer";


export type RouteType = {
    path: string;
    component: ReactNode
}

const authRoutes: RouteType[] = [
    {
        path: '/',
        component: <HomePage/>,
    },
    {
        path: '/about-us',
        component: <AboutUs/>
    },
    {
        path: '/contact-us',
        component: <ContactUs />
    },
    {
        path: '/forgot-password',
        component: <ForgotPassword />
    },
    {
        path: '/disclaimer',
        component: <Disclaimer />
    },
    {
        path: '/privacy',
        component: <Privacy />
    },
    {
        path: '/refund-policy',
        component: <RefundPolicy />
    },
    {
        path: '/terms-and-conditions',
        component: <TermsAndCondition />
    },
    {
        path: '/sign-up',
        component: <SignUp/>
    },
    {
        path: '/sign-in',
        component: <SignIn/>
    },
    {
        path: '/crm-login',
        component: <AdminLogin />
    },
    {
        path: '/crm-register',
        component: <AdminRgister />
    },
    {
        path: '/verify-email',
        component: <VerifyEmail />
    },
    {
        path: '/privacy',
        component: <Privacy />
    },
    {
        path: '*',
        component: <NotFoundPage/>
    }
]

export default authRoutes;