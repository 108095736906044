import React from 'react';

//images
import haticon from '../../assets/icons/hat.png';
import penicon from '../../assets/icons/iconpen.png';
import clockicon from '../../assets/icons/clock.png';

function Features() {
  return (
    <>
        <div className="w-full mt-8 ">
            <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:w-8/12">
                <div className="text-center mb-6">
                    {/* <p className='text-[#244886] font-normal my-2'>Quality Features</p> */}
                    <h3 className='text-xl sm:text-xl md:text-2xl lg:text-2xl font-bold text-[#0F2137]'>
                        We provide the best investment
                        <br />
                        Opportunities
                    </h3>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <div>
                        <div className="text-center rounded-lg hover:shadow-lg transition-all py-4 px-6">
                            <div className='flex justify-center'>
                                <img src={haticon} alt="hat-icon" width="80rem" height="80rem" className='my-4' />
                            </div>
                            <h3 className='font-3xl font-bold my-3 text-[#0F2137]'>Fund</h3>
                            <p className='text-[#0F2137]'>
                                Fund your wallet directly using our payment method.
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="text-center rounded-lg hover:shadow-lg transition-all py-4 px-6">
                            <div className='flex justify-center'>
                                <img src={penicon} alt="pen-icon"  width="80rem" height="80rem" className='my-4' />
                            </div>
                            <h3 className='font-3xl font-bold my-3 text-[#0F2137]'>Invest</h3>
                            <p className='text-[#0F2137]'>
                                Invest Preferred amount from your funded wallet.
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="text-center rounded-lg hover:shadow-lg transition-all py-4 px-6">
                            <div className="flex justify-center">
                                <img src={clockicon} alt="clock-icon"  width="80rem" height="80rem" className='my-4' />
                            </div>
                            <h3 className='font-3xl font-bold my-3 text-[#0F2137]'>Withdraw</h3>
                            <p className='text-[#0F2137]'>
                                Withdraw accrued Return On Investments to your account instantly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Features;