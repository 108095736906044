import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../services/config";
import logo from "../../assets/images/logo-yellow.png";

const CreatePassword = () => {
  const url: any = process.env.REACT_APP_BASE_URL;

  // local States
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [code, setCode] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  const [newPassword, setNewPassword] = useState<{
    value: string;
    error: boolean;
  }>({ value: "", error: false });

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (email.value === "" || undefined || null) {
      isValid = false;
      setEmail({ ...email, error: true });
    } else {
      setEmail({ ...email, error: false });
    }
    if (newPassword.value === "" || undefined || null) {
      isValid = false;
      setNewPassword({ ...newPassword, error: true });
    } else {
      setNewPassword({ ...newPassword, error: false });
    }
    if (code.value === "" || undefined || null) {
      isValid = false;
      setCode({ ...code, error: true });
    } else {
      setCode({ ...code, error: false });
    }
    return isValid;
  };

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // handles login
  const handlePasswordReset = () => {
    if (inputCheck()) {
      setLoading(true);
      const data = {
        email: email.value,
        code: code.value,
        new_password: newPassword.value,
      };
      axios
        .post(`${url}/create-password`, data)
        .then((res) => {
          setLoading(false);
          notify("success", "Your password successfully changed");
          window.location.href = "/sign-ing";
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };

  return (
    <>
      <div className="wrapper pt-[5.7rem]">
        <div className="mx-auto w-11/12 sm:w-9/12 md:w-7/12 lg:w-[35%] h-max">
          <div className="flex justify-center mb-4">
            <div className="">
              <img src={logo} width="100px" height="100px" alt="barcode" />
            </div>
          </div>
          <div className="bg-white w-full rounded-2xl shadow-lg py-16 px-10">
            <div className="">
              <h4 className="text-[#6A6A6A] text-xl font-bold text-center">
                Change Password
              </h4>
              <p className="text-[#BFBFBF] text-sm my-3 text-center">
                Input your previous and new password
              </p>
            </div>

            {/** Form Section */}
            <div id="form" className="mx-">
              <div className="my-6">
                <label htmlFor="email" className="text-[#BFBFBF] text-sm block">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={email.value}
                  onChange={(e) =>
                    setEmail({ ...email, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    email.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-2 w-full`}
                />
              </div>

              <div className="my-6">
                <label
                  htmlFor="newPassword"
                  className="text-[#BFBFBF] text-sm block"
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword.value}
                  onChange={(e) =>
                    setNewPassword({ ...newPassword, value: e.target.value })
                  }
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    newPassword.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-2 w-full`}
                />
              </div>

              <div className="my-6">
                <label htmlFor="code" className="text-[#BFBFBF] text-sm block">
                  Code
                </label>
                <input
                  type="text"
                  name="code"
                  value={code.value}
                  onChange={(e) => setCode({ ...code, value: e.target.value })}
                  className={`bg-white text-[#6A6A6A] border-2 ${
                    code.error ? "border-red-500" : "border-[#BFBFBF]"
                  } rounded-md px-4 py-2 w-full`}
                />
              </div>

              <div className="my-3 text-center">
                <button
                  onClick={() => handlePasswordReset()}
                  className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl "
                >
                  {loading ? "Loading..." : "Send"}
                </button>
              </div>
            </div>
            {/** Form Section */}
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default CreatePassword;
