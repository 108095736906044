import React from 'react'
import AdminRegisterComp from '../../../components/admin-dashboard/register';

const AdminRgister = () => {
  return (
    <>
        <AdminRegisterComp />
    </>
  )
}

export default AdminRgister;