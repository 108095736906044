import React from "react";
import Layout from "../../../shared/layout";
import ReferralsComp from "../../../components/users-dashboard/referrals-comp";

function Referrals() {
  return (
    <>
      <Layout>
        <ReferralsComp />
      </Layout>
    </>
  );
}

export default Referrals;
