import React, { useState } from 'react';
import { BsPlusCircleFill, BsFillDashCircleFill } from 'react-icons/bs';

type Props = {
    title: string;
    content: string;
}

const FaqTile = ({ title, content }: Props) => {
    const [display, setDisplay] = useState<boolean>(false);

    return (
        <>
            <div 
                style={{transition: 'all .4s ease'}}
                className='flex justify-start py-3 border-b-2 mb-4 cursor-pointer border-[#f4f9ff]' 
                onClick={() => setDisplay(!display)}
            >
                <div className='mx-4' style={{transition: 'all .4s ease'}}>
                    {
                        display ? <BsFillDashCircleFill className='text-[#3FDBB1]' /> : <BsPlusCircleFill className='text-[#3FDBB1]' />
                    }
                </div>
                <div>
                    <h4 className='text-lg text-[#0F2137] mb-4' style={{transition: 'all .4s ease'}}>{ title }</h4>
                    <p className={`${ display ? 'block' : 'hidden' } text-sm font-light text-[#343D48]`} style={{transition: 'all .4s ease'}}>{ content }</p>
                </div>
            </div>
        </>
    )
}

export default FaqTile;