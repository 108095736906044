import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import logo from '../../assets/images/logo-white.png';

const TopBar = () => {
    const [show, setShow] = useState(false);
    const toggleShowDrowdown = () => {
        setShow(!show);
    }

    return (
        <>
            <div className="main-content py-5 flex justify-between w-full">
                {/* logo */}
                <div>
                    <img src={logo} width="70px" height="70px" alt="logo" />
                </div>
                {/* logo */}

                {/* navigations */}
                <div className='flex justify-between list-none mr-28'>
                    <li className='text-white lg:mx-8 md:mx-6 sm:mx-4 mx-2 text-sm font-thin hover:font-bold hover:text-lg transition-all delay-75 '><Link to="/">Home</Link></li>
                    <li className='text-white lg:mx-8 md:mx-6 sm:mx-4 mx-2 text-sm font-thin hover:font-bold hover:text-lg transition-all delay-75 '><Link to="/about-us">About us</Link></li>
                    <li className='text-white lg:mx-8 md:mx-6 sm:mx-4 mx-2 text-sm font-thin hover:font-bold hover:text-lg transition-all delay-75 '><a href="#faq">FAQ</a></li>
                    <li className='text-white lg:mx-8 md:mx-6 sm:mx-4 mx-2 text-sm font-thin hover:font-bold hover:text-lg transition-all delay-75 '><Link to="/contact-us">Contact Us</Link></li>
                </div>

                <button className='border-2 cursor-pointer border-white text-white py-1 sm:px-2 md:px-4 lg:px-auto rounded-3xl hover:bg-white hover:text-black'>
                    <Link to="/sign-in" className='text-[0.85rem]'>Get Started</Link>
                </button>
            </div>

            {/* mobile view */}
            <div className="dropdownmenu mx-auto w-10/12">
                <div className='flex justify-between'>
                    <div className="mt-5" style={{width: '15%'}}>
                        <img src={logo} style={{width: '100%'}} alt="logo" />
                    </div>
                    <div className="flex justify-center">
                        <div>
                            <div className='pt-4 dropdown relative'>
                                <button className="
                                    dropdown-toggle
                                    px-6
                                    py-2.5
                                    text-white hover:border-b-2 hove                     font-medium
                                    font-lg
                                    leading-tight
                                    uppercase
                                    rounded
                                    hover:bg-white hover:shadow-lg hover:text-[#058AB3]
                                    focus:bg-white focus:text-[#058AB3] focus:shadow-lg focus:outline-none focus:ring-0
                                    active:bg-white active:shadow-lg active:text-[#058AB3]
                                    transition
                                    duration-150
                                    ease-in-out
                                    flex
                                    items-center
                                    whitespace-nowrap
                                    "
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                    onClick={() => toggleShowDrowdown()}
                                >
                                <span className='font-lg'>
                                    <FiMenu />
                                </span>
                            
                                </button>
                                <ul
                                    className={`dropdown-menu
                                    min-w-6
                                    absolute
                                    ${show ? 'block' : 'hidden'}
                                    bg-white
                                    text-base
                                    z-50
                                    py-2
                                    list-none
                                    text-left
                                    rounded-lg
                                    shadow-lg
                                    mt-1
                                    m-0
                                    bg-clip-padding
                                    border-none
                                    "`}
                                    aria-labelledby="dropdownMenuButton1"
                                    id='dropdownMenuButton1'
                                >
                                    <li>
                                        <Link
                                        className="
                                        dropdown-item
                                        text-sm
                                        py-2
                                        px-4
                                        font-normal
                                        block
                                        w-full
                                        whitespace-nowrap
                                        bg-transparent
                                        text-gray-700
                                        hover:bg-gray-100
                                        "
                                        to='/'
                                        >Home</Link>
                                    </li>
                                    <li>
                                        <Link
                                        className="
                                        dropdown-item
                                        text-sm
                                        py-2
                                        px-4
                                        font-normal
                                        block
                                        w-full
                                        whitespace-nowrap
                                        bg-transparent
                                        text-gray-700
                                        hover:bg-gray-100
                                        "
                                        to="/about-us"
                                        >About us</Link>
                                    </li>
                                    <li>
                                        <Link
                                        className="
                                        dropdown-item
                                        text-sm
                                        py-2
                                        px-4
                                        font-normal
                                        block
                                        w-full
                                        whitespace-nowrap
                                        bg-transparent
                                        text-gray-700
                                        hover:bg-gray-100
                                        "
                                        to='/faqs'
                                        >FAQ</Link>
                                    </li>
                                    
                                    <li>
                                        <Link
                                        className="
                                        dropdown-item
                                        text-sm
                                        py-2
                                        px-4
                                        font-normal
                                        block
                                        w-full
                                        whitespace-nowrap
                                        bg-transparent
                                        text-gray-700
                                        hover:bg-gray-100
                                        "
                                        to='/contact-us'
                                        >Contact Us</Link>
                                    </li>

                                    <li>
                                        <Link
                                        className="
                                        dropdown-item
                                        text-sm
                                        py-2
                                        px-4
                                        font-normal
                                        block
                                        w-full
                                        whitespace-nowrap
                                        bg-transparent
                                        text-gray-700
                                        hover:bg-gray-100
                                        "
                                        to='/sign-in'
                                        >Sign In</Link>
                                    </li>

                                    <li>
                                        <Link
                                        className="
                                        dropdown-item
                                        text-sm
                                        py-2
                                        px-4
                                        font-normal
                                        block
                                        w-full
                                        whitespace-nowrap
                                        bg-transparent
                                        text-gray-700
                                        hover:bg-gray-100
                                        "
                                        to='/sign-up'
                                        >Sign up</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar; 