import React from 'react';
import { Link } from 'react-router-dom';

// logo
import logo from '../../assets/images/logo-yellow.png';

const DisclaimerComp = () => {
  return (
    <>
        <div className='w-full'>
            <div className="relative top-0 bg-[#FF971D] text-white">
                <h1 className='ml-16 mr-auto w-10/12 py-16 text-2xl lg:text-4xl font-bold'>Disclaimer</h1>
                <div className="absolute right-4 -bottom-16 rounded-full bg-white px-6 py-10 w-max h-max shadow-lg">
                    <Link to="/">
                        <img src={logo} width="80px" height="80px" alt="logo" />
                    </Link>
                    {/* <h2>Logo</h2> */}
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">1. Information published on smarthills.org</h3>
                    <p className='text-lg font-normal my-4'>
                        Unless otherwise indicated, the website https://smarthills.org/ (hereinafter, referred to as the "Website") is our sole property which provides information and material of a general nature and as such, all source code, databases, functionality, software, web designs, audio, video, text, graphics and pictures, on the site, (also the "content") and the trademarks, service marks and logos, contained therein (the "Marks") are owned and and controlled by Smarthills
                        and are produced by copyright and trademark law and various other intellectual property rights, foreign jurisdictions and international conventions 
                    </p>
                    <p className='text-lg font-normal my-4'>
                        You are not authorized and nor should you rely on the Website for legal advice, business advice, or advice of any kind. You act at your own risk in reliance on the contents of the Website. Should you make a decision to act or not act you should contact a licensed attorney in the relevant jurisdiction in which you want or need help. 
                        In no way are the owners of, or contributors to, the Website responsible for the actions, decisions, or other behavior taken or not taken by you in reliance upon the Website 
                    </p>
                    <p className='text-lg font-normal my-4'>
                        Trading the foreign exchange market, digital markets, commodities and bonds has large potential rewards, but also large potential risk. 
                        Any opinions, news, research, analyses, prices, or other information contained on this website is provided as general market commentary, and does not constitute investment advice. 
                    </p>
                    <p className='text-lg font-normal my-4'>
                        In addition, certain trades may result in a loss greater than your entire investment. 
                        But rest assured, this does not affect your investment with Smarthills as we guarantee you returns on every investment frame. 
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">2. Translations</h3>
                    <p className='text-lg font-normal my-4'>
                        The Website may contain translations of the English version of the content available on the Website. These translations are provided only as a convenience. In the event of any conflict between the English language version and the translated version, the English language version shall take precedence. If you notice any inconsistency, please report them on Customer Support on Smarthills.
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">3. Risks related to the use of Bitcoin</h3>
                    <p className='text-lg font-normal my-4'>
                        The Website will not be responsible for any losses, damages or claims arising from events falling within the scope of the following five categories:
                    </p>

                    <p className='text-lg font-normal my-4'>
                        (1) Mistakes made by the user of any Crypto-related software or service, e.g., forgotten passwords, payments sent to wrong Bitcoin addresses, and accidental deletion of wallets.
                    </p>
                    <p className='text-lg font-normal my-4'>
                        (2) Software problems of the Website and/or any Crypto-related software or service, e.g., corrupted wallet file, incorrectly constructed transactions, unsafe cryptographic libraries, malware affecting the Website and/or any Crypto-related software or service.                   
                    </p>
                    <p className='text-lg font-normal my-4'>
                        (3) Technical failures in the hardware of the user of any Crypto-related software or service, e.g., data loss due to a faulty or damaged storage device.
                    </p>
                    <p className='text-lg font-normal my-4'>
                        (4) Security problems experienced by the user of any Crypto-related software or service, e.g., unauthorized access to users' wallets and/or accounts.
                    </p>
                    <p className='text-lg font-normal my-4'>
                        (5) Actions or inactions of third parties and/or events experienced by third parties, e.g., bankruptcy of service providers, information security attacks on service providers, and fraud conducted by third parties.
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">4. Investment risks</h3>
                    <p className='text-lg font-normal my-4'>
                        The investment in any Crypto-related currency provided can lead to loss of money over short or even long periods. 
                        The investors in any Crypto-related currency should expect prices to have large range fluctuations. 
                        The information published on the Website cannot guarantee that the investors in Crypto-related currency would not lose money due to fluctuations in market price and value.                    
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">5. Compliance with tax obligations</h3>
                    <p className='text-lg font-normal my-4'>
                        The users of the Website are solely responsible to determinate what, if any, taxes apply to their Crypto-related transactions. 
                        The owners of, or contributors to, the Website are NOT responsible for determining the taxes that apply to Bitcoin transactions.                    
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">6. The Website does not store, send, or receive Crypto-related currency</h3>
                    <p className='text-lg font-normal my-4'>
                        The Website does not store, send or receive Crypto-related currency. This is because bitcoins exist only by virtue of the ownership record maintained in the Crypto-related network. 
                        Any transfer of title in bitcoins occurs within a decentralized Bitcoin network, and not on the Website.                    
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">7. No warranties</h3>
                    <p className='text-lg font-normal my-4'>
                        The Website is provided on an "as is" basis without any warranties of any kind regarding the Website and/or any content, data, materials and/or services provided on the Website.                    
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">8. Limitation of liability</h3>
                    <p className='text-lg font-normal my-4'>
                        Unless otherwise required by law, in no event shall the owners of, or contributors to, the Website be liable for any damages of any kind, including, but not limited to, loss of use, loss of profits, or loss of data arising out of or in any way connected with the use of the Website.                    
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">9. Arbitration</h3>
                    <p className='text-lg font-normal my-4'>
                        The user of the Website agrees to arbitrate any dispute arising from or in connection with the Website or this disclaimer, except for disputes related to copyrights, logos, trademarks, trade names, trade secrets or patents.                    
                    </p>
                </div>
            </div>

            <div className='mt-8'>
                <div className='mx-auto w-11/12'>
                    <h3 className="text-lg lg:text-2xl font-bold">10. Last amendment</h3>
                    <p className='text-lg font-normal my-4'>
                        This disclaimer was amended for the last time on April 5th, 2022.                    
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default DisclaimerComp;