import React from 'react';
import PrivacyComp from '../../components/privacy-comp';

const Privacy = () => {
    return(
        <>
            <PrivacyComp />
        </>
    )
}

export default Privacy;