import React from 'react'
import VerifyEmailComp from '../../components/verify-email-comp';

const VerifyEmail = () => {
  return (
    <>
        <VerifyEmailComp />
    </>
  )
}

export default VerifyEmail;