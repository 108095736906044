import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { User } from "../../common";
import Card from "../../shared/card";
import {
  DEPOSIT_USER_WALLET,
  FUND_USER,
  RETRIEVE_USER_BY_ID,
  TOGGLE_USER_WITHDRAWAL_STATUS,
  VERIFY_USER,
} from "../../services";
import profileImg from "../../assets/images/profile.png";

function UserDetailComp() {
  const params = useParams();
  const { id } = params;
  const [userProfile, setUserProfile] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deducting, setDeducting] = useState<boolean>(false);
  const [depositing, setDepositing] = useState<boolean>(false);
  const [decreasingDeposit, setDecreasingDeposit] = useState<boolean>(false);
  const [amount, setAmount] = useState<{ value: number; error: boolean }>({
    value: 0,
    error: false,
  });
  const [reduction, setReduction] = useState<{ value: number; error: boolean }>(
    {
      value: 0,
      error: false,
    }
  );
  const [deposit, setDeposit] = useState<{ value: number; error: boolean }>({
    value: 0,
    error: false,
  });
  const [decreaseDeposit, setDecreaseDeposit] = useState<{
    value: number;
    error: boolean;
  }>({
    value: 0,
    error: false,
  });

  const notify = (type: string, msg: string) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const retrieveUsersDetail = (id: string) => {
    RETRIEVE_USER_BY_ID(id)
      .then((res) => {
        const { message, data } = res.data;
        notify("success", message);
        setUserProfile(data);
      })
      .catch((err) => {
        const { error } = err.response.data;
        setLoading(false);
        notify("error", error);
      });
  };

  const inputCheck = (): boolean => {
    let isValid: boolean = true;
    if (amount.value === undefined || null) {
      isValid = false;
      setAmount({ ...amount, error: true });
    } else {
      setAmount({ ...amount, error: false });
    }

    return isValid;
  };

  const handleFundUser = () => {
    setLoading(true);
    if (inputCheck()) {
      const data: any = {
        id: userProfile && userProfile._id,
        amount: amount.value,
      };
      FUND_USER(data)
        .then((res) => {
          setLoading(false);
          retrieveUsersDetail(data.id);
          notify("success", "User's wallet Funded");
        })
        .catch((err) => {
          const { error } = err.response.data;
          setLoading(false);
          notify("error", error);
        });
    }
  };
  const handleDeductUser = () => {
    setDeducting(true);
    if (inputCheck()) {
      const data: any = {
        id: userProfile && userProfile._id,
        amount: -reduction.value,
      };
      FUND_USER(data)
        .then((res) => {
          setDeducting(false);
          retrieveUsersDetail(data.id);
          notify("success", "User's earnings reduced");
        })
        .catch((err) => {
          const { error } = err.response.data;
          setDeducting(false);
          notify("error", error);
        });
    }
  };

  const handleDeposit = () => {
    setDepositing(true);
    if (inputCheck()) {
      const data: any = {
        id: userProfile && userProfile._id,
        amount: deposit.value,
      };
      DEPOSIT_USER_WALLET(data)
        .then((res) => {
          setDepositing(false);
          retrieveUsersDetail(data.id);
          notify("success", "User wallet balance increased");
        })
        .catch((err) => {
          const { error } = err.response.data;
          setDeducting(false);
          notify("error", error);
        });
    }
  };

  const handleDecreaseDeposit = () => {
    setDecreasingDeposit(true);
    if (inputCheck()) {
      const data: any = {
        id: userProfile && userProfile._id,
        amount: -decreaseDeposit.value,
      };
      DEPOSIT_USER_WALLET(data)
        .then((res) => {
          setDecreasingDeposit(false);
          retrieveUsersDetail(data.id);
          notify("success", "User wallet balance decreased");
        })
        .catch((err) => {
          const { error } = err.response.data;
          setDecreasingDeposit(false);
          notify("error", error);
        });
    }
  };

  const verifyUser = async (id: string) => {
    console.log(userProfile?.documentVerification);
    const { data } = await VERIFY_USER(id);
    retrieveUsersDetail(id);
    if (data.error) {
      notify("error", data.error);
    } else {
      notify("success", data.message);
    }
  };

  const toggleWithdrawalStatus = async (id: string) => {
    console.log(userProfile?.documentVerification);
    const { data } = await TOGGLE_USER_WITHDRAWAL_STATUS(id);
    retrieveUsersDetail(id);
    if (data.error) {
      notify("error", data.error);
    } else {
      notify("success", data.message);
    }
  };

  useEffect(() => {
    if (id) {
      retrieveUsersDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className="mx-auto w-11/12 sm:w-9/12 md:w-8/12 lg:w-8/12">
        {userProfile && (
          <div className="my-8">
            <Card type="lg">
              <div className="flex justify-center mb-4">
                <div className="bg-black rounded-full">
                  <img
                    src={profileImg}
                    width="50px"
                    height="50px"
                    className="rounded-full"
                    alt="barcode"
                  />
                </div>
              </div>
              <br />

              {/* users detail */}
              <div className="grid space-x-0 sm:space-x-1 md:space-x-2 lg:space-x-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <div className="my-4 px-4">
                  <p>First Name</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.firstname}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Last Name</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.lastname}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Email</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.email}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Phone</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.phone}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Role</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.role}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Register Status</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.status}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Wallet Balance</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    ${userProfile?.balance}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Earnings</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    ${userProfile?.earnings}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Password</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.password}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Verification Code</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.verificationCode}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Verification Image</p>
                  <a
                    href={userProfile?.idCard}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm sm:text-sm md:text-sm lg:text-lg text-[#FF971D] hover:text-yellow-600 hover:cursor-pointer"
                  >
                    View Image
                  </a>
                </div>
                <div className="my-4 px-4">
                  <p>Document Status</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.documentVerification === true
                      ? "Approved"
                      : "Not Approved"}
                  </h3>
                </div>
                <div className="my-4 px-4">
                  <p>Can Withdraw?</p>
                  <h3 className="text-sm sm:text-sm md:text-sm lg:text-lg">
                    {userProfile?.canWithdraw === false ? "No" : "Yes"}
                  </h3>
                </div>
                <div className="my-4 px-4"></div>
                <div className="my-4 px-4">
                  <p>Action</p>
                  <button
                    onClick={() => {
                      verifyUser(userProfile._id);
                    }}
                    className="text-sm sm:text-sm md:text-sm lg:text-lg bg-[#FF971D] rounded-md px-2 text-white"
                  >
                    Verify User
                  </button>
                </div>
                <div className="my-4 px-4">
                  <p>Action</p>
                  <button
                    onClick={() => {
                      toggleWithdrawalStatus(userProfile._id);
                    }}
                    className="text-sm sm:text-sm md:text-sm lg:text-lg bg-[#FF971D] rounded-md px-2 text-white"
                  >
                    {userProfile?.canWithdraw === false
                      ? "Activate Withdrawal"
                      : "Block Withdrawal"}
                  </button>
                </div>
              </div>
            </Card>
          </div>
        )}

        <div className="my-8">
          <Card type="lg">
            <div className="mb-4">
              <h3 className="text-2xl font-semibold">
                Increase User's Earnings
              </h3>
            </div>
            <br />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleFundUser();
              }}
            >
              <div className="my-4">
                <div className="my-3">
                  <label
                    htmlFor="amount"
                    className="text-[#BFBFBF] text-sm block"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    required
                    placeholder="Enter amount in usd"
                    value={amount.value}
                    onChange={(e) =>
                      setAmount({ ...amount, value: Number(e.target.value) })
                    }
                    className={`bg-white text-[#6A6A6A] border-2 ${
                      amount.error ? "border-red-500" : "border-[#BFBFBF]"
                    } rounded-md px-4 py-2 w-full`}
                  />
                </div>

                <div className="my-3 text-center">
                  <button className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl w-full">
                    {loading ? "Loading..." : "Fund User"}
                  </button>
                </div>
              </div>
            </form>
          </Card>
        </div>

        <div className="my-8">
          <Card type="lg">
            <div className="mb-4">
              <h3 className="text-2xl font-semibold">Deduct User's Earnings</h3>
            </div>
            <br />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDeductUser();
              }}
            >
              <div className="my-4">
                <div className="my-3">
                  <label
                    htmlFor="amount"
                    className="text-[#BFBFBF] text-sm block"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    required
                    placeholder="Enter amount in usd"
                    value={reduction.value}
                    onChange={(e) =>
                      setReduction({
                        ...reduction,
                        value: Number(e.target.value),
                      })
                    }
                    className={`bg-white text-[#6A6A6A] border-2 ${
                      reduction.error ? "border-red-500" : "border-[#BFBFBF]"
                    } rounded-md px-4 py-2 w-full`}
                  />
                </div>

                <div className="my-3 text-center">
                  <button className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl w-full">
                    {deducting ? "Loading..." : "Debit User"}
                  </button>
                </div>
              </div>
            </form>
          </Card>
        </div>
        <div className="my-8">
          <Card type="lg">
            <div className="mb-4">
              <h3 className="text-2xl font-semibold">Increase User Deposits</h3>
            </div>
            <br />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDeposit();
              }}
            >
              <div className="my-4">
                <div className="my-3">
                  <label
                    htmlFor="amount"
                    className="text-[#BFBFBF] text-sm block"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    required
                    placeholder="Enter amount in usd"
                    value={deposit.value}
                    onChange={(e) =>
                      setDeposit({
                        ...deposit,
                        value: Number(e.target.value),
                      })
                    }
                    className={`bg-white text-[#6A6A6A] border-2 ${
                      deposit.error ? "border-red-500" : "border-[#BFBFBF]"
                    } rounded-md px-4 py-2 w-full`}
                  />
                </div>

                <div className="my-3 text-center">
                  <button className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl w-full">
                    {depositing ? "Loading..." : "Deposit"}
                  </button>
                </div>
              </div>
            </form>
          </Card>
        </div>
        <div className="my-8">
          <Card type="lg">
            <div className="mb-4">
              <h3 className="text-2xl font-semibold">Decrease User Deposits</h3>
            </div>
            <br />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDecreaseDeposit();
              }}
            >
              <div className="my-4">
                <div className="my-3">
                  <label
                    htmlFor="amount"
                    className="text-[#BFBFBF] text-sm block"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    required
                    placeholder="Enter amount in usd"
                    value={decreaseDeposit.value}
                    onChange={(e) =>
                      setDecreaseDeposit({
                        ...decreaseDeposit,
                        value: Number(e.target.value),
                      })
                    }
                    className={`bg-white text-[#6A6A6A] border-2 ${
                      deposit.error ? "border-red-500" : "border-[#BFBFBF]"
                    } rounded-md px-4 py-2 w-full`}
                  />
                </div>

                <div className="my-3 text-center">
                  <button className="bg-[#FF971D] text-white py-1 px-10 rounded-2xl w-full">
                    {decreasingDeposit ? "Loading..." : "Deduct"}
                  </button>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default UserDetailComp;
