import React from "react";
import { Link } from "react-router-dom";

// logo
import logo from "../../assets/images/logo-yellow.png";

const ContactUsComp = () => {
  return (
    <>
      <div className="w-full mb-12">
        <div className="relative top-0 bg-[#FF971D] text-white">
          <h1 className="ml-16 mr-auto w-10/12 py-16 text-2xl lg:text-4xl font-bold">
            Contact US
          </h1>
          <div className="absolute right-4 -bottom-16 rounded-full bg-white px-6 py-10 w-max h-max shadow-lg">
            <Link to="/">
              <img src={logo} width="80px" height="80px" alt="logo" />
            </Link>
            {/* <h2>Logo</h2> */}
          </div>
        </div>

        <div className="mt-8">
          <div className="mx-auto w-11/12">
            <h3 className="text-lg lg:text-2xl font-bold">
              For further enquires on our page, please
            </h3>

            <div className="my-8">
              <p className="text-lg font-normal my-2">
                <strong>Contact us at:</strong>
              </p>
              <p className="text-lg font-normal my-2">
                <strong>Phone</strong> +1 (719) 401-1087
              </p>
            </div>

            <div className="my-8">
              <p className="text-lg font-normal my-2">
                <strong>Email us at:</strong>
              </p>
              <p className="text-lg font-normal my-2">
                <strong>Email</strong> support@smarthills.org
              </p>
            </div>

            <div className="my-8">
              <p className="text-lg font-normal my-2">
                <strong>Visit us at:</strong>
              </p>
              <p className="text-lg font-normal my-2">
                <strong>Location </strong> 2318 Rue Albert-Dion, Saint-Nicolas,
                QC G7A4Z4, Canada
              </p>
            </div>

            <div>
              <h3 className="text-lg lg:text-2xl font-bold">
                We at Smart-hills...
              </h3>
              <p className="text-lg font-normal my-4">
                Will always ensure that you get the best of what you deserve and
                more as we always look forward to serving you with the best. A
                happy client is a successful investor, so what are you waiting
                for?
              </p>

              <p className="text-lg font-normal my-4">
                Come now, and don't miss out on anything and be left with
                regrets and sorrow. Smarthills is here for you.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsComp;
